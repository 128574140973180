import React from 'react';
import styled from 'styled-components';
import { CopyButton } from '../buttons/CopyButton';

interface Props {
  id: string;
  text: string;
}

const CopyWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const Copy = ({ id, text }: Props): JSX.Element => {
  return (
    <CopyWrapper>
      <CopyButton id={id} text={text} />
    </CopyWrapper>
  );
};

export default Copy;
