import React, { useState } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  UserFormsFillB,
  isFormsFilledB,
  reactSelect,
  addressFieldsDisabled,
  getStateProvinceRegion
} from '../models/UserForms';
import BigButton from '../components/buttons/BigButton';
import CheckboxInput from '../components/FF/FFCheckboxInput';
import TextInput from '../components/FF/FFTextInput';
import Select from '../components/FF/FFSelect';
import { countries } from 'countries-list';
import { CartItem } from '../routes/SelectPlan';

const StyledTextInput = styled(TextInput)`
  border: none;
  border-bottom: 2px solid #55595c;
  margin: 10px 0 20px 0;
  font-size: 1em;
  width: 100%;
  color: #55595c;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;
// This can be used when a text field is beside a drop-down/select field.
const TallStyledTextInput = styled(TextInput)`
  border: none;
  border-bottom: 2px solid #55595c;
  margin: 16px 0 20px 0;
  font-size: 1em;
  width: 100%;
  color: #55595c;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;
const SelectWrapper = styled.div`
  border: none;
  border-bottom: 2px solid #55595c;
  margin: 0 0 20px 0;
`;
const StyledText = styled.span`
  color: #55595c;
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const selectStyles = {
  // TODO: linter doesn't like the 'any' here. How to fix?
  control: (base: any) => ({
    ...base
  }),

  singleValue: (base: any) => ({
    ...base,
    color: 'var(--dark-light)',
    fontSize: '1em',
    padding: '0px',
    margin: '0px'
  }),

  placeholder: () => ({
    color: '#BBBBBB'
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'var(--highlight)' : 'white',
    backgroundColor: '#48657A',
    padding: '10px 30px',
    fontSize: '1em'
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    hyphens: 'auto',
    marginTop: 0,
    textAlign: 'left',
    wordWrap: 'break-word',
    boxShadow: '5px 5px var(--highlight)'
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0
  })
};

// ----------------------------------------------------------------- Field Comps

export const LastName = (): JSX.Element => (
  <div>
    <Field<string>
      name="meta.lastName"
      component={StyledTextInput}
      placeholder="Last Name *"
    />
  </div>
);

export const FirstName = (): JSX.Element => (
  <div>
    <Field<string>
      name="meta.firstName"
      component={StyledTextInput}
      placeholder="First Name *"
    />
  </div>
);

export const OrgName = (): JSX.Element => (
  <div>
    <Field<string>
      name="account.organizationName"
      component={StyledTextInput}
      placeholder="Organization Name *"
    />
  </div>
);

// Sort the countries by label (not code) and then prepend Canada & the US to the list.
const getCountries = (): reactSelect[] => {
  // Convert the list from the library to entries for the select.
  const result: reactSelect[] = Object.entries(countries).map((entry) => ({
    label: entry[1].name,
    value: entry[0]
  }));

  // Remove Canada & the US so we can prepend them after sorting.
  const canada = result.find((entry) => entry.label === 'Canada');
  const us = result.find((entry) => entry.label === 'United States');
  const aland = result.find((entry) => entry.label === 'Åland');
  result.forEach((entry, index) => {
    if (entry === canada || entry === us) {
      result.splice(index, 1);
    }
  });

  // Sort by label (default is by country code/value).
  result.sort((entry0, entry1) => (entry0.label > entry1.label ? 1 : -1));

  // Prepend Åland for readability.
  if (aland) {
    result.unshift(aland);
  }
  // Prepend US & Canada (in that order).
  if (us) {
    result.unshift(us);
  }
  if (canada) {
    result.unshift(canada);
  }
  return result;
};

export const BillingCountry = (): JSX.Element => {
  return (
    <SelectWrapper>
      <Field<string>
        name="account.billingAddress.country"
        component={Select}
        options={getCountries()}
        styles={selectStyles}
        isSearchable={true}
        placeholder="Country *"
      />
    </SelectWrapper>
  );
};

export const BillingStateProvinceRegion = (
  values: UserFormsFillB
): JSX.Element => {
  const options = getStateProvinceRegion(values);
  // We still aren't handling when a country changes by blanking the previous value.
  if (options.length > 0) {
    if (values.account.billingAddress.stateProvinceRegion) {
      const select = values.account.billingAddress
        .stateProvinceRegion as reactSelect;
      if (select.value === undefined) {
        // Prevent enablement of the "Sign up!" button if the user has selected the wrong country (without a province list) and
        // entered a string.
        values.account.billingAddress.stateProvinceRegion = undefined;
      } else {
        // TODO: Clean this up.
        let found = false;
        for (const option of options) {
          if (option.value === select.value) {
            found = true;
            break;
          }
        }
        if (!found) {
          // If the last selected province's short code isn't in the province list of the current country, blank the province.
          // Occasional collisions on short codes may cause errors if the user doesn't select the right new province for their
          // new country.
          values.account.billingAddress.stateProvinceRegion = undefined;
          // TODO: Field is not visually blanked until user changes focus from country field.
          // At least the submit button is disabled. So the user cannot submit the wrong province.
          // Solution will likely lie in React hooks.
        }
      }
      return (
        <SelectWrapper>
          <Field<string>
            name="account.billingAddress.stateProvinceRegion"
            component={Select}
            options={options}
            styles={selectStyles}
            isSearchable={false}
            placeholder="State / Province / Region *"
            disabled={addressFieldsDisabled(values)}
          />
        </SelectWrapper>
      );
    }
    return (
      <SelectWrapper>
        <Field<string>
          name="account.billingAddress.stateProvinceRegion"
          component={Select}
          options={options}
          styles={selectStyles}
          isSearchable={true}
          placeholder="State / Province / Region *"
          disabled={addressFieldsDisabled(values)}
        />
      </SelectWrapper>
    );
  } else {
    // Value was previously set by the other component, so the value is not a string (and is a province from the previous
    // country selection). Make it a blank string.
    if (
      values.account.billingAddress.stateProvinceRegion !== undefined &&
      (values.account.billingAddress.stateProvinceRegion as reactSelect)
        .value !== undefined
    ) {
      values.account.billingAddress.stateProvinceRegion = '';
    }
    return (
      <div>
        <Field<string>
          name="account.billingAddress.stateProvinceRegion"
          component={TallStyledTextInput}
          placeholder="State / Province / Region *"
          value={''}
          disabled={addressFieldsDisabled(values)}
        />
      </div>
    );
  }
};

export const IndustryDrop = (): JSX.Element => {
  const options = [
    'Agriculture',
    'Construction',
    'Education',
    'Energy, Utilities & Resources',
    'Financial Services',
    'Government and Public Services',
    'Health care and social assistance',
    'Hospitality',
    'IT and Telecommunication',
    'Manufacturing',
    'Media, Arts & Entertainment',
    'Mining',
    'Pharmaceuticals and Life Sciences',
    'Professional services',
    'Real Estate',
    'Retail and Consumer Goods',
    'Technology and Software',
    'Transportation',
    'Other'
  ];

  return (
    <SelectWrapper>
      <Field<string>
        name="account.organizationIndustry"
        component={Select}
        options={options.map((option) => ({ value: option, label: option }))}
        styles={selectStyles}
        isSearchable={true}
        placeholder="Industry*"
      />
    </SelectWrapper>
  );
};

export const IndustryOther = (): JSX.Element => (
  <div>
    <Field<string>
      name="account.organizationIndustryOther"
      component={StyledTextInput}
      placeholder="Other Industry *"
    />
  </div>
);

export const OrgDept = (): JSX.Element => (
  <div>
    <Field<string>
      name="account.department"
      component={StyledTextInput}
      placeholder="Department"
    />
  </div>
);

export const BillingStreet = (disabled: boolean): JSX.Element => (
  <div>
    <Field<string>
      name="account.billingAddress.address"
      component={StyledTextInput}
      placeholder="Street Address *"
      disabled={disabled}
    />
  </div>
);

export const BillingCity = (disabled: boolean): JSX.Element => (
  <div>
    <Field<string>
      name="account.billingAddress.city"
      component={StyledTextInput}
      placeholder="City *"
      disabled={disabled}
    />
  </div>
);

export const BillingPostalCode = (disabled: boolean): JSX.Element => (
  <div>
    <Field<string>
      name="account.billingAddress.postalCode"
      component={StyledTextInput}
      placeholder="Postal / Zip Code *"
      disabled={disabled}
    />
  </div>
);

export const PolicyCheck = (): JSX.Element => (
  <Row className="pb-3">
    <Col md="auto">
      <Field<boolean>
        name="other.policy"
        type="checkbox"
        component={CheckboxInput}
      />
    </Col>
    <Col>
      <StyledText>
        I agree to Receptiviti&#39;s{' '}
        <a
          href="https://www.receptiviti.com/terms-of-use"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          href="https://www.receptiviti.com/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        *
      </StyledText>
    </Col>
  </Row>
);

export const SubscribeCheck = (): JSX.Element => (
  <Row>
    <Col md="auto">
      <Field<boolean>
        name="meta.subscribe"
        type="checkbox"
        component={CheckboxInput}
      />
    </Col>
    <Col>
      <StyledText>
        I want to receive emails about research and product news from
        Receptiviti. You can unsubscribe at any time.
      </StyledText>
    </Col>
  </Row>
);

export const CanadianNonResidentTaxCheck = (
  user: UserFormsFillB
): JSX.Element => {
  const country = user.account?.billingAddress?.country?.value;

  const [canadianResident, setCanadianResident] = useState(true);

  const onChangeCanadianResident = () => {
    const value = !user.account.meta.canadianResident;
    user.account.meta.canadianResident = value;
    setCanadianResident(value);
  };

  if (country !== undefined && country !== '' && country !== 'CA') {
    const orgName = user.account?.organizationName;

    return (
      <Row className="pb-3">
        <Col md="auto">
          {/*<Field<boolean>*/}
          {/*  name="account.meta.canadianResident"*/}
          {/*  value={!user.account.meta.canadianResident}*/}
          {/*  type="checkbox"*/}
          {/*  component={CheckboxInput}*/}
          {/*  onChange={onChangeCanadianResident}*/}
          {/*/>*/}
          <input
            type="checkbox"
            id="canadianResidentTaxCheck"
            value={!canadianResident ? 'true' : 'false'}
            checked={!user.account.meta.canadianResident}
            onChange={onChangeCanadianResident}
          />
        </Col>
        <Col>
          <StyledText>
            I certify that <b>“{orgName}”</b> is not resident in Canada for
            purposes of the Excise Tax Act (ETA) and that <b>“{orgName}”</b> is
            not registered for GST/HST purposes under the ETA. I agree to advise
            Receptiviti Inc. in the event there is any change to the residency
            or registration status for GST/HST purposes.
          </StyledText>
        </Col>
      </Row>
    );
  }
  return <></>;
};

export const SubmitButton = (values: UserFormsFillB): JSX.Element => {
  return (
    <ButtonWrapper>
      {isFormsFilledB(values) ? (
        <BigButton variant="outline-secondary" type="submit">
          Sign Up!
        </BigButton>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              Please fill required* fields!
            </Tooltip>
          }
          placement="right"
        >
          <BigButton
            variant="outline-secondary"
            disabled
            style={{ pointerEvents: 'none' }}
          >
            Sign Up!
          </BigButton>
        </OverlayTrigger>
      )}
    </ButtonWrapper>
  );
};

export const CheckoutButton = (
  cartItems: Map<string, CartItem>,
  handleClick: () => void
): JSX.Element => {
  return (
    <ButtonWrapper>
      {cartItems.size > 0 ? (
        <BigButton variant="outline-secondary" onClick={handleClick}>
          Checkout
        </BigButton>
      ) : (
        <BigButton
          variant="outline-secondary"
          disabled
          style={{ pointerEvents: 'none' }}
        >
          Checkout
        </BigButton>
      )}
    </ButtonWrapper>
  );
};
