import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

interface Props {
  title: string;
  body: string;
  error?: boolean;
  onClose?: () => void;
}

const Header = styled(Modal.Header)`
  border-bottom: none;
  padding: 3rem 3em 0 3rem;
  .modal-title {
    line-height: 1;
  }
  .close {
    margin: -3rem -2rem -1rem auto;
  }
`;
const Body = styled(Modal.Body)`
  padding: 0 3rem 3rem 3rem;
`;

const AlertModal = ({ title, body, error, onClose }: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const spacer = error ? (
    <div className="error-spacer" />
  ) : (
    <div className="accent-spacer" />
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Header>
        <Body>
          {spacer}
          {body}
        </Body>
      </Modal>
    </>
  );
};

export default AlertModal;
