import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { parseISO, isBefore } from 'date-fns';
import styled from 'styled-components';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import Contact from './Contact';
import Dashboard from './Dashboard';
import Score from './Score';
import SignUp from './SignUp';
import SelectPlan from './SelectPlan';
import Home from './Home';
import Loading from './Loading';
import PrivateRoute from './PrivateRoute';
import Profile from './Profile';
import Verify from './Verify';
import TrialOrPlanEnd from './TrialOrPlanEnd';
import Validation from './Validation';

const Wrapper = styled.div``;

const publicRoutes: JSX.Element[] = [
  <Route key="0" path="/contact" component={Contact} />
];

function Content(props: connectorType) {
  const { isAuthenticated, loading, user } = useAuth0();

  const LandingPage = () => {
    if (loading) {
      return <></>;
    }

    if (!isAuthenticated) {
      const match = window.location.search.match(/[?&]key=([a-z0-9]+)/);
      if (match && match.length > 1) {
        return (
          <Switch>
            <Route path="/" exact>
              <Validation signUpKey={match[1]} />
            </Route>
            {publicRoutes.map((route) => route)}
            <Route>
              <Validation signUpKey={match[1]} />
            </Route>
          </Switch>
        );
      }

      return (
        <Switch>
          <Route path="/" component={Home} exact />
          {publicRoutes.map((route) => route)}
          <Route component={Home} />
        </Switch>
      );
    }

    if (!user || !user.email_verified) {
      return (
        <Switch>
          {publicRoutes.map((route) => route)}
          <PrivateRoute path="/" component={Verify} exact />
          <PrivateRoute path="/profile" component={Profile} />
          <Route component={Loading} />
        </Switch>
      );
    }

    if (props.hasAccount === undefined) {
      return <Loading />;
    }

    if (props.hasAccount === false) {
      return (
        <Switch>
          {publicRoutes.map((route) => route)}
          <PrivateRoute path="/" component={SignUp} exact />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/score" component={SignUp} exact />
          <Route component={Loading} />
        </Switch>
      );
    }

    const now = new Date();
    if (props.apiUser) {
      if (
        props.apiUser.account.meta.statusId === 3 ||
        (props.apiUser.account.meta.statusId === 4 &&
          isBefore(parseISO(props.apiUser.account.plan.endDate), now))
      ) {
        return (
          <Switch>
            {publicRoutes.map((route) => route)}
            <PrivateRoute path="/" component={TrialOrPlanEnd} />
            <Route component={Loading} />
          </Switch>
        );
      }

      if (props.apiUser.account.meta.statusId === 5) {
        return (
          <Switch>
            {publicRoutes.map((route) => route)}
            <PrivateRoute path="/" component={SelectPlan} exact />
            <PrivateRoute path="/profile" component={Profile} />
            <Route component={Loading} />
          </Switch>
        );
      }
    }

    return (
      <Switch>
        {publicRoutes.map((route) => route)}
        <PrivateRoute path="/" component={Dashboard} exact />
        <PrivateRoute path="/score" component={Score} exact />
        <PrivateRoute path="/profile" component={Profile} />
        <Route component={Loading} />
      </Switch>
    );
  };

  return (
    <Wrapper>
      <LandingPage />
    </Wrapper>
  );
}

export default connector(Content);
