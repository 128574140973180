import React, { useState } from 'react';
import { Checkout } from '../api/checkout';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import { UserFormsSubmit, apiUserToUserFormsSubmit } from '../models/UserForms';
import Loading from './Loading';
import { CheckoutCart } from '../components/CheckoutCart';
import { Package } from '../models/Package';

export type CartItem = {
  name: string;
  cost: number;
  accessList: string[];
};

const SelectPlan = (props: connectorType) => {
  const { isAuthenticated, loading } = useAuth0();
  const checkout = Checkout(props);

  const [loadingCheckout, setLoadingCheckout] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<Map<string, CartItem>>(new Map());
  const [cartKey, setCartKey] = useState<number>(0);

  if (loading || !isAuthenticated || !props.apiUser) {
    return <Loading />;
  }

  const updateCartItems = ({ name, cost, accessList }: Package) => {
    const items = new Map(cartItems);
    if (items?.get(name)) {
      items?.delete(name);
    } else {
      items?.set(name, {
        name: name,
        cost,
        accessList
      });
    }
    setCartItems(items);
    setCartKey(items.size);
  };

  const onCheckout = () => {
    if (!props.apiUser) {
      return;
    }

    const user: UserFormsSubmit = apiUserToUserFormsSubmit(props.apiUser);

    // Reset the access list.
    user.account.plan.access.accessList = [];

    Array.from(cartItems.keys()).forEach((item: string) => {
      const accessList = cartItems.get(item)?.accessList;
      accessList && user.account.plan.access.accessList.push(...accessList);
    });

    setLoadingCheckout(true);
    checkout(user);
  };

  return (
    <CheckoutCart
      cartItems={cartItems}
      cartKey={cartKey}
      updateCartItems={updateCartItems}
      onCheckout={onCheckout}
      country={props.apiUser.account.billingAddress.country}
      loading={loadingCheckout}
    />
  );
};

export default connector(SelectPlan);
