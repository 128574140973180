import axios from 'axios';
import { useAuth0 } from '../utils/react-auth0-wrapper';
import { connectorType } from '../store/connector';
import { UserFormsSubmit } from '../models/UserForms';
import { Result } from '../models/Result';
import { ApiUser } from '../models/ApiUser';

export function GetUser(props: connectorType) {
  const { getTokenSilently } = useAuth0();

  return async () => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .get(process.env.REACT_APP_API + '/user', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            const user: ApiUser = res.data;
            if (user !== props.apiUser) {
              props.setApiUser(user);
              if (!props.hasAccount) props.setHasAccount(true);
            }
          })
          .catch((err) => {
            props.setHasAccount(false);
          });
      }
    } catch (err) {
      props.setHasAccount(false);
    }
  };
}

export function ResetUserSecret(props: connectorType) {
  const { getTokenSilently } = useAuth0();

  return async () => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .get(process.env.REACT_APP_API + '/reset-secret', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            const user: ApiUser = res.data;
            props.setApiMsg(res.data);
            props.setErrorMsg('');
            if (user !== props.apiUser) {
              props.setApiUser(user);
            }
          })
          .catch((err) => {
            props.setApiMsg('');
            props.setErrorMsg(err.message);
          });
      }
    } catch (err) {
      props.setApiMsg('');
      props.setErrorMsg(err.message);
    }
  };
}

export function SignUpAndCheckout(props: connectorType) {
  const { getTokenSilently } = useAuth0();
  return async (forms: UserFormsSubmit) => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .post(process.env.REACT_APP_API + '/user', forms, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((res) => {
            const user: ApiUser = res.data;
            props.setApiUser(user);
            if (!props.hasAccount) props.setHasAccount(true);
            props.setErrorMsg('');
          })
          .catch((err) => {
            props.setErrorMsg(err.message);
          });
      }
    } catch (err) {
      props.setErrorMsg(err.message);
    }
  };
}

export function ResendEmail(props: connectorType) {
  const { getTokenSilently } = useAuth0();

  return async () => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .get(process.env.REACT_APP_API + '/resend-email', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            const result: Result = res.data;
            props.setVerificationResult(result);
          })
          .catch((err) => {
            props.setVerificationResult({ error: err });
          });
      }
    } catch (err) {
      props.setVerificationResult({ error: err });
    }
  };
}

export function ResetPassword(props: connectorType) {
  const { getTokenSilently } = useAuth0();

  return async () => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .get(process.env.REACT_APP_API + '/reset-password', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            const result: Result = res.data;
            props.setPasswordResult(result);
          })
          .catch((err) => {
            props.setPasswordResult({ error: err });
          });
      }
    } catch (err) {
      props.setPasswordResult({ error: err });
    }
  };
}
