import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Wrapper from '../components/Wrapper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ResetPassword } from '../api/user';
import { connector, connectorType } from '../store/connector';
import { useAuth0 } from '../utils/hooks';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AlertModal from '../components/modals/AlertModal';
import Loading from './Loading';

const StyledSpan = styled.span`
  margin-right: 0.5em;
`;

const Profile = (props: connectorType) => {
  const { isAuthenticated, loading, user } = useAuth0();

  if (loading || !isAuthenticated || !props.hasAccount) {
    return <Loading />;
  }

  const resetPassword = ResetPassword(props);

  let modal: JSX.Element = <></>;
  if (props.passwordResult) {
    const onClose = () => {
      props.setPasswordResult(undefined);
    };

    if (props.passwordResult.success) {
      modal = (
        <AlertModal
          title="We've sent you an email!"
          body="Check your inbox (or spam folder) for the message and follow the link to reset your password."
          onClose={onClose}
        />
      );
    } else if (props.passwordResult.error) {
      modal = (
        <AlertModal
          title="Oops!!"
          body="We're sorry. We weren't able to reset your password. Please try again later."
          error={true}
          onClose={onClose}
        />
      );
    }
  }

  const Greetings = () => {
    let name = '';
    if (props.apiUser) {
      name = props.apiUser.meta.firstName;
    } else {
      name = user ? user.nickname : '';
    }
    return (
      <Row>
        <Col>
          <h1>Hello, {name}!</h1>
          <div className="accent-spacer" />
        </Col>
      </Row>
    );
  };
  const UserName = () => {
    let name = '';
    if (props.apiUser) {
      name = props.apiUser.meta.firstName + ' ' + props.apiUser.meta.lastName;
    } else {
      name = user ? user.nickname : '';
    }
    return (
      <Row>
        <Col>
          <StyledSpan>User:</StyledSpan>
          {name}
        </Col>
      </Row>
    );
  };

  const Email = () => {
    return (
      <Row>
        <Col>
          <StyledSpan>Email:</StyledSpan>
          {user ? user.email : ''}
          {!user.email_verified && (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  Your email is unverified!
                </Tooltip>
              }
              placement="right"
            >
              <span role="img" aria-label="crossed out">
                {String.fromCodePoint(0x274c)}
              </span>
            </OverlayTrigger>
          )}
        </Col>
      </Row>
    );
  };

  const AccountInfo = () => {
    if (!props.apiUser) return <></>;

    return (
      <>
        <Row className="mt-2">
          <Col>
            <StyledSpan>Organization:</StyledSpan>
            {props.apiUser.account.department
              ? props.apiUser.account.department +
                ' at ' +
                props.apiUser.account.organizationName
              : props.apiUser.account.organizationName}
          </Col>
        </Row>

        {props.apiUser.account.organizationIndustry ? (
          <Row>
            <Col>
              <StyledSpan>Industry:</StyledSpan>{' '}
              {props.apiUser.account.organizationIndustry}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col sm={{ span: 6, offset: 3 }} className="pl-4">
            <Greetings />
            <Row>
              <Col>
                <h3>This is a little summary of you at Receptiviti.</h3>
              </Col>
            </Row>
            <UserName />
            <Email />
            <AccountInfo />
            <Row className="mt-4">
              <Col>
                <PrimaryButton
                  variant="outline-secondary"
                  onClick={resetPassword}
                >
                  RESET PASSWORD
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {modal}
    </Wrapper>
  );
};

export default connector(Profile);
