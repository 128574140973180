import React, { createContext, ReactNode } from 'react';
import { Configuration } from '../src/models/Configuration';
import { useRequest } from '../src/api/use-request';
import { getConfiguration } from '../src/api/configuration-request';
import Loading from '../src/routes/Loading';
import { ErrorPage } from '../src/routes/Error';

export const ConfigurationContext = createContext<Configuration>({
  packages: []
});

ConfigurationContext.displayName = 'Configuration';

export function ConfigurationProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const { working, error, result } = useRequest(getConfiguration);

  return working ? (
    <Loading />
  ) : error !== undefined ? (
    <ErrorPage error={`An error occurred: ${error}`} />
  ) : result ? (
    <ConfigurationContext.Provider value={result}>
      {children}
    </ConfigurationContext.Provider>
  ) : (
    <ErrorPage error={`An error occurred: ${error}`} />
  );
}
