import provinces from 'provinces';
import { Access } from './Access';
import { Address, CountrySelectAddress } from './Address';
import { ApiUser } from './ApiUser';

export interface UserFormsSubmit {
  account: {
    id?: string;
    organizationType: number;
    organizationName: string;
    department: string;
    organizationIndustry: string;
    expectedNumCallsMonthly: number;
    expectedDataVolume: number;
    descriptionOfResearch: string;
    billingAddress: Address;
    meta: {
      canadianResident: boolean;
    };
    plan: {
      access: Access;
    };
  };
  meta: {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    productSubscription: boolean;
    researchSubscription: boolean;
  };
}

export function apiUserToUserFormsSubmit(user: ApiUser): UserFormsSubmit {
  return {
    account: {
      id: user.account.id,
      organizationType: 2,
      organizationName: user.account.organizationName,
      department: user.account.department,
      organizationIndustry: user.account.organizationIndustry,
      expectedNumCallsMonthly: 0,
      expectedDataVolume: 0,
      descriptionOfResearch: '',
      billingAddress: {
        address: user.account.billingAddress.address,
        postalCode: user.account.billingAddress.postalCode,
        city: user.account.billingAddress.city,
        stateProvinceRegion: user.account.billingAddress.stateProvinceRegion,
        country: user.account.billingAddress.country,
        email: user.account.billingAddress.email
      },
      plan: {
        access: user.account.plan.access
      },
      meta: {
        canadianResident: user.account.meta.canadianResident
      }
    },
    meta: {
      email: user.meta.email,
      firstName: user.meta.firstName,
      lastName: user.meta.lastName,
      jobTitle: user.meta.jobTitle,
      productSubscription: user.meta.productSubscription,
      researchSubscription: user.meta.researchSubscription
    }
  };
}

export interface reactSelect {
  value: string;
  label: string;
}

export interface UserFormsFillB {
  account: {
    organizationType: 1 | 2;
    organizationName: string;
    department: string;
    organizationIndustry?: reactSelect;
    organizationIndustryOther: string;
    expectedNumCallsMonthly?: reactSelect;
    billingAddress: CountrySelectAddress;
    meta: {
      canadianResident: boolean;
    };
  };
  meta: {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    subscribe: boolean;
  };
  other: {
    policy: boolean;
  };
}

export const initValsB: UserFormsFillB = {
  account: {
    organizationType: 2,
    organizationName: '',
    department: '',
    organizationIndustryOther: '',
    billingAddress: {
      address: '',
      city: '',
      stateProvinceRegion: '',
      postalCode: '',
      country: undefined
    },
    meta: {
      canadianResident: true
    }
  },
  meta: {
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    subscribe: false
  },
  other: {
    policy: false
  }
};

export function convertFormB(values: UserFormsFillB): UserFormsSubmit {
  const getIndustry = (): string => {
    if (!values.account.organizationIndustry) return '';
    return values.account.organizationIndustry.value === 'Other'
      ? values.account.organizationIndustryOther
      : values.account.organizationIndustry.value;
  };

  const getCountry = (selectAddress: CountrySelectAddress): string => {
    return !selectAddress.country ? '' : selectAddress.country.value;
  };

  const getProvince = (selectAddress: CountrySelectAddress): string => {
    if (!selectAddress.stateProvinceRegion) {
      return '';
    }
    const asReactSelect = selectAddress.stateProvinceRegion as reactSelect;
    if (asReactSelect.value !== undefined) {
      return asReactSelect.value;
    }
    return selectAddress.stateProvinceRegion as string;
  };

  const getAddress = (selectAddress: CountrySelectAddress): Address => {
    return {
      address: selectAddress.address,
      city: selectAddress.city,
      stateProvinceRegion: getProvince(selectAddress),
      postalCode: selectAddress.postalCode,
      country: getCountry(selectAddress),
      email: values.meta.email
    };
  };

  return {
    account: {
      organizationType: 2,
      organizationName: values.account.organizationName,
      department: values.account.department,
      organizationIndustry: getIndustry(),
      expectedNumCallsMonthly: 0,
      expectedDataVolume: 0,
      descriptionOfResearch: '',
      billingAddress: getAddress(values.account.billingAddress),
      plan: {
        access: { accessList: [] }
      },
      meta: {
        canadianResident: values.account.meta.canadianResident
      }
    },
    meta: {
      email: values.meta.email,
      firstName: values.meta.firstName,
      lastName: values.meta.lastName,
      jobTitle: values.meta.jobTitle,
      productSubscription: values.meta.subscribe,
      researchSubscription: values.meta.subscribe
    }
  };
}

export function isFormsFilledB(values: UserFormsFillB): boolean {
  if (!values.other.policy) return false;

  const requiredNonEmptyStrings = [
    values.meta.firstName,
    values.meta.lastName,
    values.account.organizationName,
    values.account.organizationIndustry,
    values.account.billingAddress.address,
    values.account.billingAddress.city,
    values.account.billingAddress.stateProvinceRegion,
    values.account.billingAddress.postalCode,
    values.account.billingAddress.country,
    values.meta.email
  ];

  for (const option of requiredNonEmptyStrings) {
    if (!option || option?.toString().trim() === '') {
      return false;
    }
  }

  // Check for Other Industry text fill in if "Other" is selected
  if (!values.account.organizationIndustry) return false;
  if (values.account.organizationIndustry.value === 'Other') {
    if (!values.account.organizationIndustryOther) return false;
  }

  return true;
}

export function addressFieldsDisabled(values: UserFormsFillB): boolean {
  const option = values.account.billingAddress.country;
  return !option || option.value?.toString().trim() === '';
}

interface entry {
  label: string;
  value: string;
}

export function getStateProvinceRegion(values: UserFormsFillB): Array<entry> {
  const country = values.account.billingAddress.country?.value;
  const result: Array<entry> = [];
  Object.entries(provinces).map((province) => {
    if (province[1].country === country) {
      if (province[1].short === undefined) {
        // TODO: How to handle? Maybe put in the long name? It will fail at stripe.
        // console.log('province short code undefined for: ' + province[1].name);
      } else {
        result.push({
          label: province[1].name,
          value: province[1].short ? province[1].short : ''
        });
      }
    }
    return null;
  });
  return result;
}
