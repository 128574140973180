import axios from 'axios';
import { connectorType } from '../store/connector';
import { Usage } from '../models/Usage';
import { useAuth0 } from '../utils/react-auth0-wrapper';

export function GetAccountUsage(props: connectorType) {
  const { getTokenSilently } = useAuth0();

  return async (accountId: string, interval: string, beta: boolean) => {
    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();
        axios
          .get(process.env.REACT_APP_API + '/usage/account', {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              accountId: accountId,
              interval: interval,
              beta: beta
            }
          })
          .then((res) => {
            const usage: Usage = res.data;
            beta
              ? props.setAccountBetaUsage(usage)
              : props.setAccountUsage(usage);
            // This interferes with other errors
            // props.setErrorMsg('');
          })
          .catch((err) => {
            if (beta) {
              console.log('GetAccountUsage() error for beta: ' + err.message);
              props.setAccountBetaUsage(undefined);
            } else {
              console.log('GetAccountUsage() error: ' + err.message);
              props.setAccountUsage(undefined);
            }
            // props.setErrorMsg(err.message);
          });
      }
    } catch (err) {
      beta
        ? props.setAccountBetaUsage(undefined)
        : props.setAccountUsage(undefined);
      // props.setErrorMsg(err.message);
    }
  };
}
