import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { validateSignUp } from '../api/validate';
import Wrapper from '../components/Wrapper';
import BigButton from '../components/buttons/BigButton';
import { useAuth0 } from '../utils/hooks';
import Loading from './Loading';

type Params = {
  signUpKey: string;
};

export default function Validation({ signUpKey }: Params): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const [processing, setProcessing] = useState<boolean>(true);
  const [statusCode, setStatusCode] = useState<number>(500);

  validateSignUp(signUpKey)
    .then((validation) => {
      if (!validation.valid) {
        setProcessing(false);
        return;
      }
      window.localStorage.setItem('checkout-mode', validation.mode);
      if (loginWithRedirect) {
        return loginWithRedirect({
          action: 'signup'
        });
      }
    })
    .catch((e) => {
      setStatusCode(e?.response?.status || 500);
      setProcessing(false);
    });

  if (processing) {
    return <Loading />;
  }

  let msg = '';
  switch (statusCode) {
    case 400:
      msg =
        'The key was not valid. Please ensure that you have pasted the full link.';
      break;
    case 401:
      msg = 'The key has expired. Please contact sales for a new link.';
      break;
    default:
      msg = 'An error has occurred. Please contact sales.';
      break;
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <h1 className="mb-4">Oh No!</h1>
            <h2 className="mb-4">{msg}</h2>
            <BigButton href="https://www.receptiviti.com/contact">
              Contact Us
            </BigButton>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
