import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { CartItem } from '../../routes/SelectPlan';

const CartWrapper = styled.div`
  min-height: 266px;
`;

const CartTitle = styled.div`
  border-bottom-style: solid;
  border-color: #c8c8c8;
  border-width: thick;
  font-size: 40px;
  padding-bottom: 12px;
  color: #000;
  line-height: 52px;
`;

const CartList = styled.div`
  padding: 10px 0px;
  border-bottom-style: solid;
  border-color: #c8c8c8;
  border-width: thick;
  min-height: 85px;
`;

const ListItem = styled.div`
  padding: 2px 0px;
  font-size: 18px;
  font-weight: lighter;
  line-height: 25px;
  color: #000;
`;

const PInfo = styled.div`
  font-size: 15px;
  font-style: italic;
  font-weight: 100;
  margin-top: 10px;
`;

interface cartProps {
  cartItems: Map<string, CartItem>;
  country: String;
}

export const Cart = ({ cartItems, country }: cartProps): JSX.Element => {
  const cartList = Array.from(cartItems.keys());
  const currency = country === 'CA' ? 'CAD' : 'USD';
  let cartTotal = 0;
  return (
    <CartWrapper>
      <CartTitle>Price</CartTitle>
      <CartList>
        {cartList.length > 0 ? (
          cartList.map((item: string, idx: number) => {
            if (cartItems !== undefined) {
              cartTotal += cartItems.get(item)?.cost ?? 0;
            }
            return (
              <Row key={idx}>
                <Col md={6}>
                  <ListItem style={{ textAlign: 'left' }}>
                    {item.toUpperCase()}
                  </ListItem>
                </Col>
                <Col md={6}>
                  <ListItem style={{ textAlign: 'right' }}>
                    ${cartItems.get(item)?.cost}&nbsp;{currency}
                  </ListItem>
                </Col>
              </Row>
            );
          })
        ) : (
          <ListItem style={{ color: '#000000a8' }}>Your cart is empty</ListItem>
        )}
      </CartList>

      {cartList.length > 0 ? (
        <>
          <Row>
            <Col md={6}>
              <ListItem style={{ textAlign: 'left' }}>
                <b>Total</b>
              </ListItem>
            </Col>
            <Col md={6}>
              <ListItem
                style={{
                  textAlign: 'right',
                  lineHeight: 'revert',
                  fontSize: '15px'
                }}
              >
                ${cartTotal}&nbsp;{currency} <br />
                <span>
                  + <br />
                  Applicable Taxes*{' '}
                </span>
              </ListItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListItem style={{ fontSize: '15px' }}>
                <i>250,000 words/month included</i> <br />
                <i>Minimum 3 month commitment</i>
              </ListItem>
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </CartWrapper>
  );
};

export const PaymentInfo = (): JSX.Element => {
  return (
    <Row className="my-2">
      <Col>
        <PInfo>
          Accepted payment methods are VISA, Mastercard, American Express and
          JCB
        </PInfo>
        <PInfo>*Taxes are computed at checkout</PInfo>
      </Col>
    </Row>
  );
};
