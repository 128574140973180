import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Package } from '../../models/Package';

const PlanButton = styled.div`
  width: 275px;
  height: 90px;
  background: #c8c8c8;
  color: #48657a;
  font-size: 22px;
  border: none;
  border-radius: 10px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0.7em;
  font-weight: 500;
  line-height: 52px;
  margin-top: 15px;
  margin-bottom: 15px;

  &:hover {
    background: #828282;
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    font-weight: 500;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
`;

export const CustomizeButton = styled.div`
  width: 275px;
  height: 75px;
  background: #48657a;
  color: #fff;
  font-size: 20px;
  border: none;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0.7em;
  font-weight: 500;
  line-height: 31px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const setDisplayText = (
  setText: React.Dispatch<React.SetStateAction<string>>,
  text: string
) => {
  return () => {
    setText(text);
  };
};

interface packageProps {
  package: Package;
  updateCart: (pkg: Package) => void;
  selected: boolean;
}

export function PackageButton(props: packageProps): JSX.Element {
  const [name, setText] = useState(props.package.name.toUpperCase());
  const handleClick = () => {
    props.updateCart(props.package);
  };
  return (
    <Row className="my-4">
      <Col md={10}>
        <PlanButton
          onMouseOver={setDisplayText(setText, `$${props.package.cost}/month`)}
          onMouseLeave={setDisplayText(
            setText,
            props.package.name.toUpperCase()
          )}
          onClick={handleClick}
          className={`${props.selected ? 'selected-package' : ''}`}
        >
          <div>{name}</div>
        </PlanButton>
      </Col>
      <Col md={2} className="py-4 my-2">
        <input
          type="checkbox"
          id={name}
          value={props.package.cost}
          checked={props.selected}
          onChange={handleClick}
        />
      </Col>
    </Row>
  );
}
