import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connectorType } from '../../store/connector';
import { inTrialPeriod } from '../../utils/common';
import { isoDateFormat } from '../../utils/dates';
import { toDollars } from '../../utils/numbers';
import { Info, InfoRow } from './Info';
import DiscountInfo from './DiscountInfo';
import OverageInfo from './OverageInfo';

const Plan = (props: connectorType): JSX.Element => {
  if (!props.apiUser || !props.apiUser.account || !props.apiUser.account.plan) {
    return <></>;
  }

  const plan = props.apiUser.account.plan;

  let rows: InfoRow[] = [];

  if (inTrialPeriod(plan.trialEndDate)) {
    rows = [
      {
        key: 'Trial Start Date',
        value: plan.trialStartDate ? isoDateFormat(plan.trialStartDate) : 'N/A'
      },
      {
        key: ' Trial End Date',
        value: plan.trialEndDate ? isoDateFormat(plan.trialEndDate) : 'N/A'
      }
    ];
  }

  if (plan.startDate) {
    rows.push({
      key: 'Start Date',
      value: isoDateFormat(plan.startDate)
    });
  }

  rows.push({
    key: 'Word Limit',
    value: plan.wordLimit.toLocaleString('en-US')
  });

  if (plan.startDate) {
    rows = [
      ...rows,
      {
        key: 'Base Price',
        value: toDollars(plan.basePrice)
      },
      ...OverageInfo(plan),
      ...DiscountInfo(plan)
    ];
  }

  if (plan.accessBeta) {
    rows.push(
      {
        key: 'Beta Access',
        value: 'Yes'
      },
      {
        key: 'Beta Word Limit',
        value: plan.betaWordLimit.toLocaleString('en-US')
      }
    );
  }

  return (
    <Row>
      <Col md={12}>
        <Info rows={rows} />
      </Col>
    </Row>
  );
};

export default Plan;
