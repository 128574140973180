import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { useAuth0 } from '../utils/hooks';
import history from '../utils/history';
import { connector, connectorType } from '../store/connector';
import { GetUser } from '../api/user';
import Content from './Content';
import NavBar from '../components/NavBar';

const App = (props: connectorType) => {
  const { user } = useAuth0();
  const getApiUser = GetUser(props);
  const [loadingUser, setLoadingUser] = useState(false);
  if (
    user &&
    user.email_verified &&
    props.hasAccount === undefined &&
    !loadingUser
  ) {
    setLoadingUser(true);
    getApiUser();
  }

  return (
    <>
      <Router history={history}>
        <NavBar />
        <Content />
      </Router>
    </>
  );
};

export default connector(App);
