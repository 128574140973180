import { Plan } from '../../models/Plan';
import { InfoRow } from './Info';

const OverageInfo = (plan: Plan): InfoRow[] => {
  if (!plan) {
    return [];
  }

  if (!plan.overageEnabled) {
    return [
      {
        key: 'Overage Enabled',
        value: 'No'
      }
    ];
  }

  return [
    {
      key: 'Overage Enabled',
      value: 'Yes'
    },
    {
      key: 'Overage Word Limit',
      value: plan.overageWordLimit
        ? plan.overageWordLimit.toLocaleString('en-US')
        : 'N/A'
    },
    {
      key: 'Overage Price per Word',
      value: plan.overagePricePerWord
        ? '$' + plan.overagePricePerWord.toString()
        : '$0.00'
    }
  ];
};

export default OverageInfo;
