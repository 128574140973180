import { AxiosInstance } from 'axios';
import { assertIsConfiguration, Configuration } from '../models/Configuration';

export async function getConfiguration(
  api: AxiosInstance
): Promise<Configuration> {
  const { data } = await api.get('signup-config');

  if (!assertIsConfiguration(data)) {
    throw new TypeError('Unexpected structure of configuration response');
  }

  return data;
}
