import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import { ScoreCSV } from '../api/score';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Loading from './Loading';
import LoadingImage from '../components/LoadingImage';
import { ScoreReport } from '../components/ScoreReport';
import { GetAccountUsage } from '../api/usage';

const column = 'column';

const baseStyle = {
  cursor: 'pointer',
  flex: 1,
  flexDirection: 'column' as typeof column,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bbb',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: 'var(--primary)'
};

const LoadingWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
`;

const PopupAlertWrapper = styled.div`
  margin-top: 20px;
`;

const maxSize = 10 * 1048576;

const Score = (props: connectorType) => {
  const { loading } = useAuth0();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections
  } = useDropzone({
    accept: '.csv',
    minSize: 0,
    maxSize: maxSize
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  );

  const getUsage = useCallback(GetAccountUsage(props), []);
  const [loadingUsage, setLoadingUsage] = useState(false);
  const [popupAlert, setPopupAlert] = useState<JSX.Element | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      props.apiUser &&
      props.apiUser.account &&
      !props.accountUsage &&
      !loadingUsage
    ) {
      setLoadingUsage(true);
      getUsage(props.apiUser.account.id, 'day', false);
    }

    if (popupAlert) {
      const popupAlertTimer = setTimeout(() => {
        setPopupAlert(undefined);
      }, 6000);
      return () => clearTimeout(popupAlertTimer);
    }
  }, [
    props.apiUser,
    props.accountUsage,
    loadingUsage,
    setLoadingUsage,
    getUsage,
    popupAlert,
    setPopupAlert
  ]);

  let isFileTooLarge = false;
  let invalidType = false;
  if (fileRejections.length > 0) {
    isFileTooLarge = fileRejections[0].file.size > maxSize;
    invalidType = fileRejections[0].file.name.slice(-4) !== '.csv';
  }

  const scoreCSV = ScoreCSV(props);

  if (loading || !props.apiUser) {
    return <Loading />;
  }

  const handleSubmit = () => {
    if (!acceptedFiles.length) {
      return;
    }
    scoreCSV(acceptedFiles[0]);
    setLoadingUsage(false);
  };

  const handleTestPopup = () => {
    const w = window.open('test popup');

    if (w) {
      try {
        w.document.open();
        w.document.write("You're ready to score a CSV!");
        w.document.close();
        return;
      } catch (e) {
        // do nothing
      }
    }

    setPopupAlert(
      <PopupAlertWrapper>
        <Alert variant="warning">
          It looks like popups are being blocked by your browser - please
          disable your popup blocker to score a CSV.
        </Alert>
      </PopupAlertWrapper>
    );
  };

  let score: JSX.Element = <></>;
  if (
    props.accountUsage &&
    props.accountUsage.summary.wordsRemaining <= 0 &&
    props.accountUsage.summary.allowedWords <= 0
  ) {
    score = (
      <>
        <Alert variant="secondary">
          Plan word limit reached. Please contact support.
        </Alert>
      </>
    );
  } else if (props.scoringLoading) {
    score = (
      <>
        <p>This may take a while, depending on the size of the file...</p>
        <LoadingWrapper>
          <LoadingImage />
        </LoadingWrapper>
      </>
    );
  } else {
    score = (
      <>
        <p>Once you are all set up, upload your CSV:</p>
        <div {...getRootProps({ style })} className="mb-3">
          <input {...getInputProps()} name="csv" />
          {!isDragActive && !acceptedFiles.length && (
            <div>Drag and drop a file here, or click to select a file.</div>
          )}
          {isDragActive && <div>Drop it!</div>}
          {!isDragActive && isFileTooLarge && (
            <div className="text-danger">
              File is too large. The maximum size is 10MB.
            </div>
          )}
          {!isDragActive && invalidType && (
            <div className="text-danger">Only *.csv files are accepted.</div>
          )}
          {!isDragActive && acceptedFiles.length > 0 && (
            <div>{acceptedFiles[0].name}</div>
          )}
        </div>
        <PrimaryButton type="submit" onClick={handleSubmit}>
          SUBMIT
        </PrimaryButton>
      </>
    );
  }

  let warning: JSX.Element = <></>;
  if (props.errorMsg) {
    warning = (
      <>
        <div className="accent-spacer" />
        <Alert variant="danger">{props.errorMsg}</Alert>
      </>
    );
  }

  return (
    <Container className="mt-3 pb-3">
      <Row>
        <Col>
          <h2>Score a CSV</h2>
          <div className="accent-spacer" />
          <p>
            Using the form below, you can upload a CSV of text to have it scored
            and returned as a separate CSV.
          </p>
          <p>Things to note about the text CSV you upload:</p>
          <ul>
            <li>
              <span>
                It should consist of <b>two</b> columns:
              </span>
              <ol>
                <li>
                  A unique identifier, which will be returned with the scores.
                </li>
                <li>A text sample to be analyzed.</li>
              </ol>
            </li>
            <li>
              It should <b>not</b> have a header line.
            </li>
            <li>
              It should not exceed <b>25,000 rows</b> or <b>10MB</b>.
            </li>
            <li>
              If you are saving it from Excel, use the <b>CSV UTF-8</b> option.
            </li>
          </ul>
          <p>Things to note about the scoring process:</p>
          <ul>
            <li>
              The CSV you receive <b>will</b> have a header line.
            </li>
            <li>
              Your unique identifier will be labeled as <b>request_id</b> and
              will be the first column.
            </li>
            <li>
              Please do <b>not</b> navigate away from this page until scoring
              has completed.
            </li>
            <li>
              Once scoring finishes, there will be a one-time use{' '}
              <b>Download Scores</b> button, which will disappear if you
              navigate away from this site. It will cease to work after one
              hour.
            </li>
            <li>
              If a row in the input file does not have any text, it will not be
              scored and will not appear in the output.
            </li>
            <li>
              If you surpass your word limit while a CSV is being scored, the
              output will include the rows after the limit was hit but they will
              not contain any scores.
            </li>
            <li>
              If you submit a file with more than 25,000 rows, only the first
              25,000 will be included in the output.
            </li>
            <li>
              If you upload a CSV after your word limit has been reached, you
              will see an error.
            </li>
          </ul>
          <p>
            Before submitting a file, first ensure that your browser settings
            will allow a popup window by clicking this button:
          </p>
          <PrimaryButton onClick={handleTestPopup}>Test Popup</PrimaryButton>
          {popupAlert}
          <div className="accent-spacer" />
          {score}
          {ScoreReport(props)}
          {warning}
        </Col>
      </Row>
    </Container>
  );
};

export default connector(Score);
