import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const PrimaryButton = styled(Button)`
  border-radius: 0px;
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
  text-decoration: none;
  text-transform: uppercase;

  &:visited {
    color: var(--primary);
  }

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    color: var(--white);
    background-color: var(--primary);
  }
`;

export default PrimaryButton;
