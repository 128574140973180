import React from 'react';
import { connectorType } from '../store/connector';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import PrimaryButton from './buttons/PrimaryButton';
import { Alert } from 'react-bootstrap';

export const ScoreReport = (props: connectorType): JSX.Element => {
  if (!props.scoreReport) {
    return <></>;
  }

  const handleDownload = () => {
    const w = window.open(props.scoreReport?.url, 'scores.csv');
    if (!w) {
      props.setErrorMsg('Could not open a new window to download file.');
    }
  };

  const download =
    +props.scoreReport.rowsScored > 0 ? (
      <>
        <p>
          You can download your scores by clicking below. If you navigate away
          from this page or wait more than one hour, they will no longer be
          available.
        </p>
        <PrimaryButton type="submit" onClick={handleDownload}>
          Download Scores
        </PrimaryButton>
      </>
    ) : (
      <Alert variant="danger">
        None of the rows in your CSV could be scored.
      </Alert>
    );

  return (
    <div>
      <div className="accent-spacer" />
      <Row>
        <Col>
          <div className="mb-3">
            <h4>Scores for {props.scoreReport.fileName}</h4>
            {download}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover size="sm" responsive={true}>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Rows Submitted</td>
                <td>{props.scoreReport.totalRows.toLocaleString('en')}</td>
              </tr>
              <tr>
                <td>Rows Scored</td>
                <td>{props.scoreReport.rowsScored.toLocaleString('en')}</td>
              </tr>
              <tr>
                <td>Elapsed Time</td>
                <td>
                  {props.scoreReport.elapsedTime
                    ? props.scoreReport.elapsedTime
                    : 'N/A'}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col></Col>
      </Row>
      <br />
    </div>
  );
};
