import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CheckoutButton } from '../components/FormsFields';
import Wrapper from '../components/Wrapper';
import LoadingImage from '../components/LoadingImage';
import { Package as PackageType } from '../models/Package';
import { Cart, PaymentInfo } from '../components/FF/Cart';
import { PackageButton } from '../components/buttons/PackageButton';
import { ConfigurationContext } from '../configuration-context';
import { CartItem } from '../routes/SelectPlan';

const PlanInfo = styled.div`
  font-size: 15px;
  color: #48657a;

  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const LoadingWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
`;

interface CheckoutProps {
  cartKey: number;
  cartItems: Map<string, CartItem>;
  updateCartItems: (pkg: PackageType) => void;
  onCheckout: () => void;
  country: string;
  loading: boolean;
}

export const CheckoutCart = (props: CheckoutProps): JSX.Element => {
  const { packages } = useContext(ConfigurationContext);

  useEffect(() => {
    if (packages.length === 1) {
      props.updateCartItems(packages[0]);
    }
  }, [packages]);

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={5}></Col>
          <Col md={7}>
            <h3>Step 2 of 3 - Choose one or more plans for Checkout</h3>
          </Col>
        </Row>
        <Row style={{ height: '480px' }}>
          <Col md={5} className="my-5">
            <div className="call-to-action">
              <div>Pick</div>
              <div>Your</div>
              <div>Plan</div>
            </div>
          </Col>
          <Col md={3}>
            <div className="accent-spacer" />
            {packages.map((item, idx) => (
              <PackageButton
                key={idx}
                package={item}
                updateCart={props.updateCartItems}
                selected={props.cartItems.has(item.name)}
              ></PackageButton>
            ))}
          </Col>
          <Col md={4} className="my-4 px-5">
            <Cart
              key={props.cartKey}
              cartItems={props.cartItems}
              country={props.country}
            ></Cart>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                {props.loading ? (
                  <LoadingWrapper>
                    <LoadingImage />
                  </LoadingWrapper>
                ) : (
                  CheckoutButton(props.cartItems, props.onCheckout)
                )}
              </Col>
              <Col md={2}></Col>
            </Row>
            {props.cartKey > 0 ? <PaymentInfo /> : ''}
          </Col>
        </Row>
        <Row>
          <Col className="offset-lg-2">
            <PlanInfo>
              Your first 10 days are on us! Note that an overage fee of
              $0.002/word is applied to any billing cycle in which the
              word-limit threshold is exceeded. Complete details of our trial
              and cancellation policy can be found&nbsp;
              <a
                href="https://www.receptiviti.com/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . Questions? Check out our{' '}
              <a
                href="https://docs.receptiviti.com/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>{' '}
              or{' '}
              <a href="/contact" target="_blank" rel="noopener noreferrer">
                Contact us
              </a>
              .
            </PlanInfo>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
