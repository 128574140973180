import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export interface InfoRow {
  key: string;
  value: JSX.Element | string | number;
}

interface Props {
  rows: InfoRow[];
}

export const SRow = styled(Row)`
  margin-bottom: 12px;
`;

export const Info: React.FC<Props> = ({ rows }: Props) => {
  return (
    <>
      {rows.map((row: InfoRow, i: number) => (
        <SRow key={i}>
          <Col md={4}>
            <b>{row.key}</b>
          </Col>
          <Col>{row.value}</Col>
        </SRow>
      ))}
    </>
  );
};
