import React, { useState } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { GetAccountUsage } from '../api/usage';
import { connector, connectorType } from '../store/connector';
import RefreshButton from '../components/buttons/RefreshButton';
import LoadingImage from '../components/LoadingImage';
import Plan from '../components/dashboard/Plan';
import KeyPair from '../components/dashboard/KeyPair';
import UsageChart from '../components/dashboard/UsageChart';

const Footer = styled.footer`
  background: var(--gray-light);
  height: 40px;
  padding-top: 10px;
`;

const Dashboard = (props: connectorType) => {
  const getUsage = GetAccountUsage(props);
  const [loadingUsage, setLoadingUsage] = useState(false);
  const [loadingBetaUsage, setLoadingBetaUsage] = useState(false);

  if (!props.apiUser) {
    return <></>;
  }

  if (
    props.apiUser &&
    props.apiUser.account &&
    !props.accountUsage &&
    !loadingUsage
  ) {
    setLoadingUsage(true);
    getUsage(props.apiUser.account.id, 'day', false);
  }

  if (
    props.apiUser &&
    props.apiUser.account &&
    props.apiUser.account.plan.accessBeta &&
    !props.accountBetaUsage &&
    !loadingBetaUsage
  ) {
    setLoadingBetaUsage(true);
    getUsage(props.apiUser.account.id, 'day', true);
  }

  const refresh = () => {
    if (!props.apiUser) {
      return;
    }
    setLoadingUsage(false);
    props.setAccountUsage(undefined);
  };

  const refreshBeta = () => {
    if (!props.apiUser) {
      return;
    }
    setLoadingBetaUsage(false);
    props.setAccountBetaUsage(undefined);
  };

  const AccountInfo = () => {
    if (props.apiUser && props.apiUser.account) {
      return <Plan {...props} />;
    }
    return <></>;
  };

  let usageSection: JSX.Element = <></>;
  if (props.apiUser && props.apiUser.account && props.accountUsage) {
    usageSection = (
      <>
        <UsageChart
          plan={props.apiUser.account.plan}
          summary={props.accountUsage?.summary}
          beta={false}
        />
      </>
    );
  } else {
    usageSection = (
      <Row className="justify-content-center">
        <LoadingImage />
      </Row>
    );
  }

  let betaUsageSection: JSX.Element = <></>;
  if (props.apiUser && props.apiUser.account && props.accountBetaUsage) {
    betaUsageSection = (
      <>
        <UsageChart
          plan={props.apiUser.account.plan}
          summary={props.accountBetaUsage?.summary}
          beta={true}
        />
      </>
    );
  } else {
    betaUsageSection = (
      <Row className="justify-content-center">
        <LoadingImage />
      </Row>
    );
  }

  let betaSection: JSX.Element = <></>;
  if (props.apiUser.account.plan.accessBeta) {
    betaSection = (
      <>
        <Row>
          <Col>
            <h2>
              Your Beta Usage{' '}
              <RefreshButton
                id="refresh-beta-usage"
                label="Refresh beta usage data"
                onClick={refreshBeta}
              />
            </h2>
            <div className="accent-spacer" />
          </Col>
        </Row>
        <Row>
          <Col>{betaUsageSection}</Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Container className="mt-3 pb-3">
        <Row>
          <Col md={6}>
            <h2>Your Plan Details</h2>
            <div className="accent-spacer" />
          </Col>
          <Col md={6}>
            <h2>Your API Key Pair</h2>
            <div className="accent-spacer" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AccountInfo />
          </Col>
          <Col md={6}>
            <KeyPair />
            <p style={{ fontSize: '14px' }}>
              Your organization has accepted Receptiviti&rsquo;s API Terms of
              Use. Please ensure you understand the Terms your organization has
              agreed to prior to your use of the API.
              <br />
              Our standard terms can be found at{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.receptiviti.com/terms-of-use"
              >
                https://www.receptiviti.com/terms-of-use
              </a>
              . If you have any questions, please contact sales@receptiviti.com
            </p>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <Col>
            <h2>
              Your API Usage{' '}
              <RefreshButton
                id="refresh-usage"
                label="Refresh usage data"
                onClick={refresh}
              />
            </h2>
            <div className="accent-spacer" />
            {usageSection}
          </Col>
        </Row>
        {betaSection}
      </Container>
      <Footer className="fixed-bottom">
        <Container>
          <Row>
            <Col>
              <a
                href="https://www.receptiviti.com/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3"
              >
                Terms of Use
              </a>{' '}
              <a
                href="https://www.receptiviti.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

export default connector(Dashboard);
