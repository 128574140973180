import { ApiUser } from '../models/ApiUser';
import { Result } from '../models/Result';
import { Usage } from '../models/Usage';
import { ScoreReport } from '../api/score';

export const SET_HAS_ACCOUNT = 'SET_HAS_ACCOUNT';
export const SET_API_USER = 'SET_API_USER';
export const TOGGLE_SHOW_CODE_KEYS = 'TOGGLE_SHOW_CODE_KEYS';
export const SET_CODE_ENDPOINT = 'SET_CODE_ENDPOINT';
export const SET_CODE_LANGUAGE = 'SET_CODE_LANGUAGE';
export const SET_API_MSG = 'SET_API_MSG';
export const SET_ERROR_MSG = 'SET_ERROR_MSG';
export const SET_ACCOUNT_USAGE = 'SET_ACCOUNT_USAGE';
export const SET_ACCOUNT_BETA_USAGE = 'SET_ACCOUNT_BETA_USAGE';
export const SET_CONTACT_RESULT = 'SET_CONTACT_RESULT';
export const SET_PASSWORD_RESULT = 'SET_PASSWORD_RESULT';
export const SET_VERIFICATION_RESULT = 'SET_VERIFICATION_RESULT';
export const SET_SCORING_LOADING = 'SET_SCORING_LOADING';
export const SET_SCORE_REPORT = 'SET_SCORE_REPORT';

export interface State {
  hasAccount: boolean | undefined;
  apiUser?: ApiUser;
  apiMsg?: string;
  errorMsg?: string;
  showCodeKeys: boolean;
  codeEndpoint: string;
  codeLanguage: string;
  accountUsage?: Usage;
  accountBetaUsage?: Usage;
  contactResult: Result | undefined;
  passwordResult: Result | undefined;
  verificationResult: Result | undefined;
  scoringLoading: boolean | undefined;
  scoreReport: ScoreReport | undefined;
}

interface SetHasAccount {
  type: typeof SET_HAS_ACCOUNT;
  bool: boolean | undefined;
}
interface SetApiUser {
  type: typeof SET_API_USER;
  apiUser: ApiUser;
}
interface SetApiMsg {
  type: typeof SET_API_MSG;
  msg: string;
}
interface SetErrorMsg {
  type: typeof SET_ERROR_MSG;
  msg: string;
}
interface ToggleShowCodeKeys {
  type: typeof TOGGLE_SHOW_CODE_KEYS;
}
interface SetCodeEndpoint {
  type: typeof SET_CODE_ENDPOINT;
  codeEndpoint: string;
}
interface SetCodeLanguage {
  type: typeof SET_CODE_LANGUAGE;
  codeLanguage: string;
}
interface SetAccountUsage {
  type: typeof SET_ACCOUNT_USAGE;
  accountUsage: Usage | undefined;
}
interface SetAccountBetaUsage {
  type: typeof SET_ACCOUNT_BETA_USAGE;
  accountBetaUsage: Usage | undefined;
}
interface SetContactResult {
  type: typeof SET_CONTACT_RESULT;
  contactResult: Result | undefined;
}
interface SetPasswordResult {
  type: typeof SET_PASSWORD_RESULT;
  passwordResult: Result | undefined;
}
interface SetVerificationResult {
  type: typeof SET_VERIFICATION_RESULT;
  verificationResult: Result | undefined;
}
interface SetScoringLoading {
  type: typeof SET_SCORING_LOADING;
  scoringLoading: boolean | undefined;
}
interface SetScoreReport {
  type: typeof SET_SCORE_REPORT;
  scoreReport: ScoreReport | undefined;
}

export type actions =
  | SetHasAccount
  | SetApiUser
  | SetErrorMsg
  | SetApiMsg
  | ToggleShowCodeKeys
  | SetCodeEndpoint
  | SetCodeLanguage
  | SetAccountUsage
  | SetAccountBetaUsage
  | SetContactResult
  | SetPasswordResult
  | SetVerificationResult
  | SetScoringLoading
  | SetScoreReport;
