import React from 'react';
import styled from 'styled-components';
import { ShowButton } from '../buttons/ShowButton';

interface Props {
  onShow: () => void;
  onHide: () => void;
  initialShow: boolean;
}

const ToggleWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 32px;
`;

const ToggleKeys = ({ onShow, onHide, initialShow }: Props): JSX.Element => {
  return (
    <ToggleWrapper>
      <ShowButton
        id="show-keys-button"
        showLabel="Show API Secret"
        hideLabel="Hide API Secret"
        onShow={onShow}
        onHide={onHide}
        initialShow={initialShow}
      />
    </ToggleWrapper>
  );
};

export default ToggleKeys;
