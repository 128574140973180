import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiEye, HiEyeOff } from 'react-icons/hi';

interface Props {
  id: string;
  showLabel: string;
  hideLabel: string;
  onShow: () => void;
  onHide: () => void;
  initialShow: boolean;
}

const showImage: JSX.Element = (
  <HiEye style={{ cursor: 'pointer', fill: '#c1d869' }} />
);
const hideImage: JSX.Element = (
  <HiEyeOff style={{ cursor: 'pointer', fill: '#c1d869' }} />
);

export const ShowButton = ({
  id,
  showLabel,
  hideLabel,
  onShow,
  onHide,
  initialShow
}: Props): JSX.Element => {
  const [label, setLabel] = useState(initialShow ? hideLabel : showLabel);
  const [image, setImage] = useState(initialShow ? hideImage : showImage);

  const toggle = () => {
    if (label === showLabel) {
      setLabel(hideLabel);
      setImage(hideImage);
      onShow();
    } else {
      setLabel(showLabel);
      setImage(showImage);
      onHide();
    }
  };

  return (
    <div className="show-button" onClick={toggle}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>{label}</Tooltip>}
      >
        {image}
      </OverlayTrigger>
    </div>
  );
};
