import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Field } from 'react-final-form';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import { SubmitContactForm } from '../api/contact';
import { ContactFormsFill } from '../models/Contact';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Wrapper from '../components/Wrapper';
import AlertModal from '../components/modals/AlertModal';
import Loading from './Loading';

const required = (value: any) => (value ? undefined : 'Required');

const Contact = (props: connectorType) => {
  const { isAuthenticated, loading, user } = useAuth0();
  if (loading) {
    return <Loading />;
  }

  let initialValues = {};
  if (props.contactResult && props.contactResult.payload) {
    initialValues = props.contactResult.payload;
  } else if (isAuthenticated) {
    initialValues = {
      authId: user.sub,
      authEmail: user.email,
      replyTo: user.email
    };
  }

  const submitForm = SubmitContactForm(props);
  const onSubmit = (values: ContactFormsFill) => {
    submitForm(values);
  };

  let modal: JSX.Element = <></>;
  if (props.contactResult) {
    const onClose = props.contactResult.success
      ? () => {
          props.setContactResult(undefined);
        }
      : () => {
          // do nothing
        };

    if (props.contactResult.success) {
      modal = (
        <AlertModal
          title="We've received your message!"
          body="Someone from our team will be in touch."
          onClose={onClose}
        />
      );
    } else if (props.contactResult.error) {
      modal = (
        <AlertModal
          title="Oops!!"
          body="We're sorry. We weren't able to send your message. Please try again later."
          error={true}
          onClose={onClose}
        />
      );
    }
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={6}>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <h2>Contact Receptiviti for assistance:</h2>
                  <div className="accent-spacer" />
                  <Field name="replyTo" validate={required}>
                    {({ input, meta }) => (
                      <div className="form-group">
                        <h3 className="form-label">Your Email</h3>{' '}
                        {meta.error && meta.touched && (
                          <Badge variant="danger">{meta.error}</Badge>
                        )}
                        <input
                          {...input}
                          type="email"
                          className="form-control"
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="subject" validate={required}>
                    {({ input, meta }) => (
                      <div className="form-group">
                        <h3 className="form-label">Subject</h3>{' '}
                        {meta.error && meta.touched && (
                          <Badge variant="danger">{meta.error}</Badge>
                        )}
                        <input {...input} className="form-control" />
                      </div>
                    )}
                  </Field>
                  <Field name="body" validate={required}>
                    {({ input, meta }) => (
                      <div className="form-group">
                        <h3 className="form-label">Body</h3>{' '}
                        {meta.error && meta.touched && (
                          <Badge variant="danger">{meta.error}</Badge>
                        )}
                        <textarea
                          {...input}
                          className="form-control"
                          rows={20}
                        />
                      </div>
                    )}
                  </Field>
                  <PrimaryButton type="submit">SUBMIT</PrimaryButton>
                </form>
              )}
            />
          </Col>
        </Row>
      </Container>
      {modal}
    </Wrapper>
  );
};

export default connector(Contact);
