import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../utils/hooks';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from '../assets/logo_navbar.png';

const SNavLink = styled(Nav.Link)`
  color: var(--white);
  font-size: 14px;
  &:hover {
    color: var(--gray-light);
  }
`;

const SNavDropdownItem = styled(Nav.Link)`
  font-style: normal;
  font-weight: bold;
  color: var(--white);
  padding: 8px 20px 8px 20px;
  &:hover {
    color: var(--gray-light);
    background-color: transparent;
  }
`;

const SNavDropdownItemLogOut = styled(SNavDropdownItem)`
  color: var(--white);
  &:hover {
    color: var(--gray-light);
  }
`;

//! ---------------------------------------------------------------------- LOGIN

export function NavBarLogin(): JSX.Element {
  const { loginWithRedirect } = useAuth0();

  const loginHandler = () => {
    if (loginWithRedirect) {
      return loginWithRedirect();
    }
  };

  return (
    <Nav.Item>
      <SNavLink onClick={loginHandler}>Log In</SNavLink>
    </Nav.Item>
  );
}

//! --------------------------------------------------------------------- LOGOUT

export function NavDropLogout(): JSX.Element {
  const { logout } = useAuth0();

  const logoutHandler = () => {
    if (logout) {
      return logout();
    }
  };

  return (
    <SNavDropdownItemLogOut onClick={logoutHandler}>
      Log Out
    </SNavDropdownItemLogOut>
  );
}
//! ------------------------------------------------------------- DROPDOWN MAKER

function NavDropItem(linkText: string, destination: string): JSX.Element {
  const history = useHistory();

  function handleClick() {
    history.push(destination);
  }

  return <SNavDropdownItem onClick={handleClick}>{linkText}</SNavDropdownItem>;
}

//! ----------------------------------------------------------- RECEPTIVITI LOGO

const SBrand = styled(Image)`
  cursor: pointer;
  height: 20px;
`;

export function NavBarBrand(): JSX.Element {
  const history = useHistory();

  function handleClick() {
    history.push('/');
  }

  return (
    <Navbar.Brand onClick={handleClick}>
      <SBrand src={logo} id="nav_logo" alt="*Receptiviti" />
    </Navbar.Brand>
  );
}

//! ------------------------------------------------------------- DROPDOWN ITEMS

export function NavDropProfile(): JSX.Element {
  return NavDropItem('Profile', '/profile');
}

export function NavDropContact(): JSX.Element {
  return NavDropItem('Contact', '/contact');
}
