import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Wrapper;
