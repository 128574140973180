import axios from 'axios';
import { ApiUser } from '../models/ApiUser';
import { UserFormsSubmit } from '../models/UserForms';
import { connectorType } from '../store/connector';
import { useAuth0 } from '../utils/react-auth0-wrapper';

export function SignUp(props: connectorType): (forms: UserFormsSubmit) => void {
  const { getTokenSilently } = useAuth0();
  return async (forms: UserFormsSubmit) => {
    if (!getTokenSilently) {
      props.setErrorMsg('Failed to get token');
      return;
    }
    const token = await getTokenSilently();

    axios
      .post(process.env.REACT_APP_API + '/sign-up', forms, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        const user: ApiUser = res.data;
        props.setApiUser(user);
        props.setHasAccount(true);
      })
      .catch((err) => {
        props.setErrorMsg(err.message);
      });
  };
}
