import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0 } from '../utils/react-auth0-wrapper';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType;
}

const PrivateRoute = ({
  component: Component,
  path,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      if (!isAuthenticated && loginWithRedirect) {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname }
        });
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props: RouteProps) =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
