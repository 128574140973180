import {
  actions,
  SET_ACCOUNT_USAGE,
  SET_ACCOUNT_BETA_USAGE,
  SET_API_MSG,
  SET_API_USER,
  SET_CODE_ENDPOINT,
  SET_CODE_LANGUAGE,
  SET_CONTACT_RESULT,
  SET_ERROR_MSG,
  SET_HAS_ACCOUNT,
  SET_PASSWORD_RESULT,
  SET_SCORE_REPORT,
  SET_SCORING_LOADING,
  SET_VERIFICATION_RESULT,
  State,
  TOGGLE_SHOW_CODE_KEYS
} from './actions';

const initialState: State = {
  apiUser: undefined,
  hasAccount: undefined,
  showCodeKeys: false,
  codeEndpoint: 'single',
  codeLanguage: 'curl',
  accountUsage: undefined,
  accountBetaUsage: undefined,
  contactResult: undefined,
  passwordResult: undefined,
  verificationResult: undefined,
  scoringLoading: undefined,
  scoreReport: undefined
};

export function reducer(state = initialState, action: actions): State {
  switch (action.type) {
    case SET_HAS_ACCOUNT: {
      return {
        ...state,
        hasAccount: action.bool
      };
    }
    case SET_API_USER: {
      return {
        ...state,
        apiUser: action.apiUser
      };
    }
    case SET_API_MSG: {
      return {
        ...state,
        apiMsg: action.msg
      };
    }
    case SET_ERROR_MSG: {
      return {
        ...state,
        errorMsg: action.msg
      };
    }
    case TOGGLE_SHOW_CODE_KEYS: {
      return {
        ...state,
        showCodeKeys: !state.showCodeKeys
      };
    }
    case SET_CODE_ENDPOINT: {
      return {
        ...state,
        codeEndpoint: action.codeEndpoint
      };
    }
    case SET_CODE_LANGUAGE: {
      return {
        ...state,
        codeLanguage: action.codeLanguage
      };
    }
    case SET_ACCOUNT_USAGE: {
      return {
        ...state,
        accountUsage: action.accountUsage
      };
    }
    case SET_ACCOUNT_BETA_USAGE: {
      return {
        ...state,
        accountBetaUsage: action.accountBetaUsage
      };
    }
    case SET_CONTACT_RESULT: {
      return {
        ...state,
        contactResult: action.contactResult
      };
    }
    case SET_PASSWORD_RESULT: {
      return {
        ...state,
        passwordResult: action.passwordResult
      };
    }
    case SET_VERIFICATION_RESULT: {
      return {
        ...state,
        verificationResult: action.verificationResult
      };
    }
    case SET_SCORING_LOADING: {
      return {
        ...state,
        scoringLoading: action.scoringLoading
      };
    }
    case SET_SCORE_REPORT: {
      return {
        ...state,
        scoreReport: action.scoreReport
      };
    }
    default:
      return state;
  }
}
