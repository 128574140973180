import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

interface Props {
  title: string;
  body: string;
  error?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const Header = styled(Modal.Header)`
  border-bottom: none;
  padding: 3rem 3em 0 3rem;
  .modal-title {
    line-height: 1;
  }
  .close {
    margin: -3rem -2rem -1rem auto;
  }
`;
const Body = styled(Modal.Body)`
  padding: 0 3rem 3rem 3rem;
`;

const ConfirmModal = ({
  title,
  body,
  error,
  onConfirm,
  onCancel
}: Props): JSX.Element => {
  const [show, setShow] = useState(true);

  const handleCancel = () => {
    setShow(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setShow(false);
    onConfirm();
  };

  const spacer = error ? (
    <div className="error-spacer" />
  ) : (
    <div className="accent-spacer" />
  );

  return (
    <>
      <Modal show={show} onHide={handleCancel} centered={true}>
        <Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Header>
        <Body>
          {spacer}
          {body}
        </Body>
        <Modal.Footer>
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>Confirm</PrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
