import React, { useState } from 'react';
import styled from 'styled-components';
import { ResetUserSecret } from '../../api/user';
import { connector, connectorType } from '../../store/connector';
import Pre from '../Pre';
import PrimaryButton from '../buttons/PrimaryButton';
import ConfirmModal from '../modals/ConfirmModal';
import Copy from './Copy';
import ToggleSecret from './ToggleSecret';

const KeyWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const KeyPair = (props: connectorType) => {
  const resetUserSecret = ResetUserSecret(props);
  const [showSecret, setShowSecret] = useState(false);
  const [showModal, setShowModal] = useState(false);

  if (!props.apiUser) {
    return <></>;
  }

  const secret = showSecret
    ? props.apiUser.apiSecret
    : '********************************************************';

  const toggleSecret = () => {
    setShowSecret(!showSecret);
  };

  const onConfirm = () => {
    setShowModal(false);
    resetUserSecret();
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const modal = showModal ? (
    <ConfirmModal
      title="Rotate Secret?"
      body="Are you sure you want to rotate your API secret?"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  ) : (
    <></>
  );

  const confirmRotate = () => {
    setShowModal(true);
  };

  return (
    <>
      <h6>API KEY</h6>
      <KeyWrapper>
        <Pre>{props.apiUser.apiKey}</Pre>
        <Copy id="copy-key" text={props.apiUser.apiKey} />
      </KeyWrapper>
      <h6>API SECRET</h6>
      <KeyWrapper>
        <Pre>{secret}</Pre>
        <Copy id="copy-secret" text={props.apiUser.apiSecret} />
        <ToggleSecret
          onShow={toggleSecret}
          onHide={toggleSecret}
          initialShow={showSecret}
        />
      </KeyWrapper>
      <p className="clearfix">
        <PrimaryButton
          variant="outline-secondary"
          className="float-right"
          onClick={confirmRotate}
        >
          Rotate Secret
        </PrimaryButton>
      </p>
      {modal}
    </>
  );
};

export default connector(KeyPair);
