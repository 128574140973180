import styled from 'styled-components';

const Pre = styled.pre`
  background: rgb(40, 43, 46);
  color: #69c1d8;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

export default Pre;
