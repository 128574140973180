import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { UserFormsSubmit } from '../models/UserForms';
import { connectorType } from '../store/connector';
import { useAuth0 } from '../utils/react-auth0-wrapper';

interface Session {
  sessionId: string;
}

export function SignUpAndCheckout(
  props: connectorType
): (forms: UserFormsSubmit) => void {
  const { getTokenSilently } = useAuth0();
  return async (forms: UserFormsSubmit) => {
    if (!process.env.REACT_APP_STRIPE_KEY) {
      props.setErrorMsg('Failed to get Stripe key');
      return;
    }
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    if (!getTokenSilently) {
      props.setErrorMsg('Failed to get token');
      return;
    }
    const token = await getTokenSilently();

    if (!stripe) {
      props.setErrorMsg('Failed to load Stripe');
      return;
    }

    axios
      .post(process.env.REACT_APP_API + '/checkout', forms, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        const session: Session = res.data;
        stripe
          .redirectToCheckout({
            sessionId: session.sessionId
          })
          .then((res) => {
            console.log(res);
          });
        props.setErrorMsg('');
      })
      .catch((err) => {
        props.setErrorMsg(err.message);
      });
  };
}

export function Checkout(
  props: connectorType
): (forms: UserFormsSubmit) => void {
  const { getTokenSilently } = useAuth0();
  return async (forms: UserFormsSubmit) => {
    if (!process.env.REACT_APP_STRIPE_KEY) {
      props.setErrorMsg('Failed to get Stripe key');
      return;
    }
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    if (!getTokenSilently) {
      props.setErrorMsg('Failed to get token');
      return;
    }
    const token = await getTokenSilently();

    if (!stripe) {
      props.setErrorMsg('Failed to load Stripe');
      return;
    }

    axios
      .put(process.env.REACT_APP_API + '/checkout', forms, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        const session: Session = res.data;
        stripe
          .redirectToCheckout({
            sessionId: session.sessionId
          })
          .then((res) => {
            console.log(res);
          });
        props.setErrorMsg('');
      })
      .catch((err) => {
        props.setErrorMsg(err.message);
      });
  };
}
