import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { RiFileCopyFill } from 'react-icons/ri';

interface Props {
  id: string;
  text: string;
}

const defaultLabel = 'Copy to Clipboard';
const copiedLabel = 'Copied!';

export const CopyButton = ({ id, text }: Props): JSX.Element => {
  const [label, setLabel] = useState(defaultLabel);
  const onCopy = () => {
    setLabel(copiedLabel);
  };
  const onToggle = () => {
    if (label === defaultLabel) {
      return;
    }
    setTimeout(() => {
      setLabel(defaultLabel);
    }, 250);
  };

  return (
    <div className="copy-button">
      <OverlayTrigger
        placement="top"
        onToggle={onToggle}
        overlay={<Tooltip id={id}>{label}</Tooltip>}
      >
        <CopyToClipboard text={text} onCopy={onCopy}>
          <RiFileCopyFill style={{ cursor: 'pointer', fill: '#c1d869' }} />
        </CopyToClipboard>
      </OverlayTrigger>
    </div>
  );
};
