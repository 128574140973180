import axios from 'axios';
import { connectorType } from '../store/connector';
import { ContactFormsFill } from '../models/Contact';
import { Result } from '../models/Result';

export function SubmitContactForm(props: connectorType) {
  return async (values: ContactFormsFill) => {
    try {
      axios
        .post(process.env.REACT_APP_API + '/contact', values)
        .then((res) => {
          const result: Result = res.data;
          props.setContactResult(result);
        })
        .catch((err) => {
          props.setContactResult({
            error: err,
            payload: values
          });
        });
    } catch (err) {
      props.setContactResult({
        error: err,
        payload: values
      });
    }
  };
}
