import axios from 'axios';

type Validation = {
  valid: boolean;
  mode: string;
};

function isValidation(obj: unknown): obj is Validation {
  const validation = obj as Validation;
  return (
    !!validation &&
    typeof validation.valid === 'boolean' &&
    typeof validation.mode === 'string'
  );
}

export async function validateSignUp(signUpKey: string): Promise<Validation> {
  const { data } = await axios.get(
    process.env.REACT_APP_API + '/validate?key=' + signUpKey
  );
  if (isValidation(data)) {
    return data;
  }
  throw new Error(`Unexpected result type: ${JSON.stringify(data)}`);
}
