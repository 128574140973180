import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  UserFormsFillB,
  initValsB,
  addressFieldsDisabled
} from '../models/UserForms';
import {
  FirstName,
  LastName,
  OrgName,
  IndustryDrop,
  IndustryOther,
  OrgDept,
  PolicyCheck,
  SubscribeCheck,
  SubmitButton,
  BillingCity,
  BillingStateProvinceRegion,
  BillingStreet,
  BillingPostalCode,
  BillingCountry,
  CanadianNonResidentTaxCheck
} from '../components/FormsFields';
import Wrapper from '../components/Wrapper';

const CallToAction = styled(Col)`
  padding-top: 80px;
`;

//TODO - Fix the type of user
interface SignupFormProps {
  user: any;
  onSubmit: (values: UserFormsFillB) => void;
}

export const SignupForm = (props: SignupFormProps): JSX.Element => {
  // initial form values
  const init = initValsB;
  const email: string = props.user && props.user.email ? props.user.email : '';
  if (props.user && props.user.email) init.meta.email = email;

  const mode = window.localStorage.getItem('checkout-mode');

  return (
    <Wrapper>
      <Container style={{ height: '810px' }}>
        <Row>
          <CallToAction md={6}>
            <div className="call-to-action">
              <div>
                {' '}
                <br />
              </div>
              <div>Let&#39;s get</div>
              <div>to know</div>
              <div>you</div>
            </div>
          </CallToAction>
          <Col md={6}>
            <br />
            {mode === 'stripe' ? (
              <>
                <h3>
                  Step 1 of 3 - Billing Information for after your Free Trial
                  Ends
                </h3>
                <div className="accent-spacer" />
              </>
            ) : (
              <br />
            )}
            <Form
              onSubmit={props.onSubmit}
              initialValues={init}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <h4>Your Name</h4>
                  <Row>
                    <Col>
                      <FirstName />
                    </Col>
                    <Col>
                      <LastName />
                    </Col>
                  </Row>
                  <br />
                  <h4>Billing Address</h4>
                  <br />
                  <Row>
                    <Col>
                      <BillingCountry />
                    </Col>
                    <Col>{BillingStateProvinceRegion(values)}</Col>
                  </Row>
                  <Row>
                    <Col>{BillingStreet(addressFieldsDisabled(values))}</Col>
                  </Row>
                  <Row>
                    <Col>{BillingCity(addressFieldsDisabled(values))}</Col>
                    <Col>
                      {BillingPostalCode(addressFieldsDisabled(values))}
                    </Col>
                  </Row>
                  <br />
                  <h4>Organization Details</h4>
                  <Row>
                    <Col>
                      <OrgName />
                    </Col>
                    <Col>
                      <OrgDept />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <IndustryDrop />
                      {values.account.organizationIndustry &&
                        values.account.organizationIndustry.value ===
                          'Other' && <IndustryOther />}
                    </Col>
                  </Row>
                  {CanadianNonResidentTaxCheck(values)}
                  <PolicyCheck />
                  <SubscribeCheck />
                  <div className="clearfix">
                    <div className="float-right">{SubmitButton(values)}</div>
                  </div>
                </form>
              )}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
