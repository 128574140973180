import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Wrapper from '../components/Wrapper';
import LoadingImage from '../components/LoadingImage';

const Loading = (): JSX.Element => {
  return (
    <Wrapper>
      <Container>
        <Row className="justify-content-center">
          <LoadingImage />
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Loading;
