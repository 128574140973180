import { Package } from './Package';

export type Configuration = {
  packages: Package[];
};

function assertIsPackage(p: Package): boolean {
  return (
    typeof p.name === 'string' &&
    typeof p.cost === 'number' &&
    p.accessList.every((access) => typeof access === 'string')
  );
}

export function assertIsConfiguration(obj: unknown): obj is Configuration {
  if (!obj) {
    throw new Error('object unexpectedly falsey');
  }

  const configuration = obj as Configuration;

  return configuration.packages.every((p) => assertIsPackage(p));
}
