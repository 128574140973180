import { Plan } from '../../models/Plan';
import { isoDateFormat } from '../../utils/dates';
import { toDollars } from '../../utils/numbers';
import { InfoRow } from './Info';

const DiscountInfo = (plan: Plan): InfoRow[] => {
  if (!plan || !plan.discountBasePrice) {
    return [];
  }

  return [
    {
      key: 'Discount End Date',
      value: isoDateFormat(plan.discountEndDate || '') ?? 'N/A'
    },
    {
      key: 'Discount Base Price',
      value: toDollars(plan.discountBasePrice)
    },
    {
      key: 'Discount Price per Word',
      value: plan.discountPricePerWord
        ? '$' + plan.discountPricePerWord.toString()
        : '$0.00'
    }
  ];
};

export default DiscountInfo;
