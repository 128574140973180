import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';

const BigButton = styled(PrimaryButton)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 15px 25px;
`;

export default BigButton;
