import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAuth0 } from '../utils/hooks';
import Wrapper from '../components/Wrapper';
import BigButton from '../components/buttons/BigButton';
import { Dictionary } from '../models/Dictionary';

const Home = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  const loginHandler = () => {
    if (loginWithRedirect) {
      return loginWithRedirect();
    }
  };

  const parsedQuery: Dictionary<string> = {};
  if (window.location.search.includes('message=')) {
    let queryString = window.location.search;
    if (queryString.startsWith('?')) {
      queryString = queryString.substring(1);
    }
    const queryParams = queryString.split('&');
    queryParams.forEach((qp) => {
      const [key, val] = qp.split('=');
      parsedQuery[key] = decodeURIComponent(val);
    });
  }

  return (
    <Wrapper>
      {parsedQuery['message'] ===
        'Your email was verified. You can continue using the application.' &&
      parsedQuery['success'] === 'true' &&
      parsedQuery['code'] === 'success' &&
      parsedQuery['supportSignUp'] === 'true' ? (
        <Container>
          <Row>
            <Col className="call-to-action" md={6}>
              <div>Email</div>
              <div>verified</div>
            </Col>
            <Col className="call-to-action-details" md={5}>
              <Row>
                <Col md={10}>
                  <h2 className="mt-3">You can log into the app now.</h2>
                  <div className="accent-spacer" />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-between">
                <Col>
                  <BigButton onClick={loginHandler} className="ml-4">
                    Log in!
                  </BigButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : parsedQuery['success'] === 'false' ? (
        <Container>
          <Row>
            <Col className="call-to-action" md={6}>
              <div>Email</div>
              <div>link</div>
              <div>failed</div>
            </Col>
            <Col className="call-to-action-details" md={5}>
              <Row>
                <Col md={10}>
                  <h2 className="mt-3">
                    Error message:
                    <br /> {parsedQuery['message']}
                  </h2>
                  <div className="accent-spacer" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col>
              <Row>
                <Col md={10}>
                  <h1 className="mt-3">Get Started Today!</h1>
                  <div className="accent-spacer" />
                  <p>
                    Receptiviti&apos;s leading-edge computational language
                    psychology platform helps you understand the emotions,
                    drives, and traits that affect human behaviour.
                  </p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <BigButton href="https://www.receptiviti.com/speak-with-an-expert">
                    Speak with an expert!
                  </BigButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </Wrapper>
  );
};

export default Home;
