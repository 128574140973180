import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const PrimaryButton = styled(Button)`
  border-radius: 0px;
  background-color: transparent;
  border-color: var(--dark-light);
  color: var(--dark-light);
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:visited,
  &:not(:disabled):not(.disabled):active {
    color: var(--white);
    border-color: var(--dark-light);
    background-color: var(--dark-light);
    text-decoration: none;
  }
`;

export default PrimaryButton;
