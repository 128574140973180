// return a Title Case string
export function titleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

// returns true when the plan is in trial period
export function inTrialPeriod(trialEndDate: string): boolean {
  return trialEndDate
    ? Date.parse(trialEndDate) >= Date.parse(new Date().toUTCString())
    : false;
}
