import React, { useState } from 'react';
import { useAuth0 } from '../utils/hooks';
import { SignUpAndCheckout } from '../api/checkout';
import { SignUp } from '../api/sign-up';
import { connector, connectorType } from '../store/connector';
import {
  UserFormsFillB,
  convertFormB,
  UserFormsSubmit
} from '../models/UserForms';
import Loading from './Loading';
import { SignupForm } from '../components/SignupForm';
import { CheckoutCart } from '../components/CheckoutCart';
import { CartItem } from './SelectPlan';

const STAGES = ['SIGNUP', 'CHECKOUT', 'LOADING'] as const;
type Stage = typeof STAGES[number];

const SignUpPage = (props: connectorType) => {
  const { isAuthenticated, loading, user } = useAuth0();

  const signUpAndCheckout = SignUpAndCheckout(props);
  const signUp = SignUp(props);

  const [loadingCheckout, setLoadingCheckout] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>('SIGNUP');
  const [userFormValues, setUserFormValues] = useState<UserFormsSubmit>(
    {} as UserFormsSubmit
  );
  const [cartItems, setCartItems] = useState<Map<string, CartItem>>(new Map());
  const [cartKey, setCartKey] = useState<number>(0);

  const mode = window.localStorage.getItem('checkout-mode');

  if (loading || !isAuthenticated) {
    return <Loading />;
  }

  const updateCartItems = ({ name, cost, accessList }: CartItem) => {
    const items = new Map(cartItems);
    if (items?.get(name)) {
      items?.delete(name);
    } else {
      items?.set(name, {
        name,
        cost,
        accessList
      });
    }
    setCartItems(items);
    setCartKey(items.size);
  };

  const onSignUp = (values: UserFormsFillB) => {
    const user = convertFormB(values);
    setUserFormValues(user);
    if (mode === 'bypass') {
      setStage('LOADING');
      signUp(user);
    } else {
      setStage('CHECKOUT');
    }
  };

  const onCheckout = () => {
    const user: UserFormsSubmit = { ...userFormValues };
    Array.from(cartItems.keys()).forEach((item: string) => {
      const accessList = cartItems.get(item)?.accessList;
      accessList && user.account.plan.access.accessList.push(...accessList);
    });
    setLoadingCheckout(true);
    signUpAndCheckout(user);
  };

  switch (stage) {
    case 'SIGNUP':
      return <SignupForm onSubmit={onSignUp} user={user} />;
    case 'CHECKOUT':
      return (
        <CheckoutCart
          cartItems={cartItems}
          cartKey={cartKey}
          updateCartItems={updateCartItems}
          onCheckout={onCheckout}
          country={userFormValues.account.billingAddress.country}
          loading={loadingCheckout}
        />
      );
    default:
      return <Loading />;
  }
};

export default connector(SignUpPage);
