import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import Wrapper from '../components/Wrapper';
import Loading from './Loading';

const TrialOrPlanEnd = (props: connectorType) => {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <Loading />;
  }

  const planOrTrial =
    props.apiUser?.account.meta.statusId === 3 ? 'trial' : 'plan';

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col className="call-to-action" md={6}>
            <div>Your</div>
            <div>{planOrTrial}</div>
            <div>ended</div>
          </Col>
          <Col className="call-to-action-details" md={5}>
            <Row>
              <Col md={10}>
                <h2>Time to renew your plan!</h2>
                <div className="accent-spacer" />
                <p>
                  Contact sales@receptiviti.com in order to re-enable site
                  access/paid features.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default connector(TrialOrPlanEnd);
