import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export function isoDateFormat(iso: string): string {
  if (!iso) {
    return '';
  }
  const date = parseISO(iso);
  const f = format(date, 'LLL d, yyyy (h:mm:ss aaaa OOOO)');
  return f;
}
