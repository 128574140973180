import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiRefresh } from 'react-icons/hi';

interface Props {
  id: string;
  label: string;
  onClick: () => void;
}

const RefreshButton = ({ id, label, onClick }: Props): JSX.Element => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={id}>{label}</Tooltip>}
    >
      <HiRefresh
        style={{ cursor: 'pointer', fill: '#c1d869' }}
        onClick={onClick}
      />
    </OverlayTrigger>
  );
};

export default RefreshButton;
