import * as React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { Auth0Provider } from './utils/react-auth0-wrapper';
import history from './utils/history';
import config from './utils/config';
import App from './routes/App';
import { reducer } from './store/reducer';
import { ReCaptchaInstance } from './models/ReCaptcha';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './styles.css';
import { ConfigurationProvider } from './configuration-context';

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
  }
}

const store = createStore(reducer, devToolsEnhancer({}));

const onRedirectCallback = (appState?: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const rootElement = document.getElementById('root');
render(
  <Provider store={store}>
    <Auth0Provider
      domain={config.authDomain}
      client_id={config.authClientId}
      redirect_uri={window.location.origin}
      audience={config.authAudience}
      onRedirectCallback={onRedirectCallback}
    >
      <ConfigurationProvider>
        <App />
      </ConfigurationProvider>
    </Auth0Provider>
  </Provider>,
  rootElement
);
