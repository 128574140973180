import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ResendEmail } from '../api/user';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import BigButton from '../components/buttons/BigButton';
import Wrapper from '../components/Wrapper';
import AlertModal from '../components/modals/AlertModal';
import Loading from './Loading';

const Verify = (props: connectorType) => {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <Loading />;
  }

  const resendEmail = ResendEmail(props);

  let modal: JSX.Element = <></>;
  if (props.verificationResult) {
    if (props.verificationResult.success) {
      modal = (
        <AlertModal
          title="We've sent you an email!"
          body={'A new verification email has been sent to ' + user.email + '.'}
        />
      );
    } else if (props.verificationResult.error) {
      modal = (
        <AlertModal
          title="Oops!!"
          body="We're sorry. We weren't able to resend your verification. Please try again later."
          error={true}
        />
      );
    }
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col className="call-to-action" md={6}>
            <div>Verify</div>
            <div>your</div>
            <div>email</div>
          </Col>
          <Col className="call-to-action-details" md={5}>
            <Row>
              <Col md={10}>
                <h2>We’ve sent you an email!</h2>
                <div className="accent-spacer" />
                <p>
                  You&#39;re almost there! An email is on its way to
                  <br />
                  {user.email}.
                </p>
                <p>
                  Click on the link in the email to verify your account.
                  Don&#39;t forget to check your spam folder, too. It will be
                  coming from no-reply@auth.receptiviti.com.
                </p>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-between">
              <Col md={4}>
                <BigButton href="/">Continue</BigButton>
              </Col>
              <Col>
                <BigButton onClick={resendEmail} className="ml-4">
                  Resend Email
                </BigButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {modal}
    </Wrapper>
  );
};

export default connector(Verify);
