import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Wrapper from '../components/Wrapper';

export const ErrorPage = ({ error }: { error: string }): JSX.Element => {
  return (
    <Wrapper>
      <Container>
        <Row className="justify-content-center">
          <div className="alert alert-danger">{error}</div>
        </Row>
      </Container>
    </Wrapper>
  );
};
