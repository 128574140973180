import axios from 'axios';
import { useAuth0 } from '../utils/react-auth0-wrapper';
import { connectorType } from '../store/connector';

interface ScoreResponse {
  error?: string;
  scoreReport?: ScoreReport;
}

export interface ScoreReport {
  id: string;
  rowsScored: number;
  totalRows: number;
  elapsedTime: string;
  // calculated on client side;
  url: string | undefined;
  fileName: string | undefined;
}

const errMsg =
  'An error occurred. Please contact support if you need assistance.';

function getCustomError(msg?: string): string {
  if (!msg) {
    return errMsg;
  }
  return (
    'An error occurred: ' +
    msg +
    '. Please contact support if you need assistance.'
  );
}

export function ScoreCSV(props: connectorType): (csv: File) => void {
  const { getTokenSilently } = useAuth0();

  return async (csv: File) => {
    props.setScoringLoading(true);
    props.setScoreReport(undefined);
    props.setErrorMsg('');

    try {
      if (getTokenSilently) {
        const token = await getTokenSilently();

        const formData = new FormData();
        formData.append('csv', csv);
        axios
          .post(process.env.REACT_APP_API + '/score', formData, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            const scoreResponse: ScoreResponse = res.data;
            if (scoreResponse.scoreReport?.id) {
              scoreResponse.scoreReport.url =
                process.env.REACT_APP_API +
                '/score/' +
                scoreResponse.scoreReport.id +
                '?token=' +
                token;
              scoreResponse.scoreReport.fileName = csv.name;
              props.setScoreReport(scoreResponse.scoreReport);
            } else {
              props.setErrorMsg(getCustomError(scoreResponse.error));
            }
            props.setScoringLoading(false);
            props.setAccountUsage(undefined);
          })
          .catch((err) => {
            props.setErrorMsg(
              getCustomError(
                err.response?.data?.error || 'the server could not be reached'
              )
            );
            props.setScoringLoading(false);
            props.setAccountUsage(undefined);
          });
      } else {
        props.setErrorMsg(
          'An authorization error occurred. Please ensure that you are logged in.'
        );
        props.setScoringLoading(false);
      }
    } catch (err) {
      props.setErrorMsg(errMsg);
      props.setScoringLoading(false);
    }
  };
}
