import { connect, ConnectedProps } from 'react-redux';
import { ApiUser } from '../models/ApiUser';
import { Result } from '../models/Result';
import { Usage } from '../models/Usage';
import {
  State,
  SET_HAS_ACCOUNT,
  SET_API_MSG,
  SET_ERROR_MSG,
  SET_API_USER,
  TOGGLE_SHOW_CODE_KEYS,
  SET_CODE_ENDPOINT,
  SET_CODE_LANGUAGE,
  SET_ACCOUNT_USAGE,
  SET_ACCOUNT_BETA_USAGE,
  SET_CONTACT_RESULT,
  SET_PASSWORD_RESULT,
  SET_VERIFICATION_RESULT,
  SET_SCORING_LOADING,
  SET_SCORE_REPORT
} from './actions';
import { ScoreReport } from '../api/score';

const mapState = (state: State) => ({
  hasAccount: state.hasAccount,
  apiUser: state.apiUser,
  apiMsg: state.apiMsg,
  errorMsg: state.errorMsg,
  showCodeKeys: state.showCodeKeys,
  codeEndpoint: state.codeEndpoint,
  codeLanguage: state.codeLanguage,
  accountUsage: state.accountUsage,
  accountBetaUsage: state.accountBetaUsage,
  contactResult: state.contactResult,
  passwordResult: state.passwordResult,
  verificationResult: state.verificationResult,
  scoringLoading: state.scoringLoading,
  scoreReport: state.scoreReport
});

const mapDispatch = {
  setHasAccount: (bool: boolean | undefined) => ({
    type: SET_HAS_ACCOUNT,
    bool: bool
  }),
  setApiUser: (apiUser: ApiUser) => ({ type: SET_API_USER, apiUser: apiUser }),
  setApiMsg: (msg: string) => ({ type: SET_API_MSG, msg }),
  setErrorMsg: (msg: string) => ({ type: SET_ERROR_MSG, msg }),
  toggleShowCodeKeys: () => ({ type: TOGGLE_SHOW_CODE_KEYS }),
  setCodeEndpoint: (codeEndpoint: string) => ({
    type: SET_CODE_ENDPOINT,
    codeEndpoint
  }),
  setCodeLanguage: (codeLanguage: string) => ({
    type: SET_CODE_LANGUAGE,
    codeLanguage
  }),
  setAccountUsage: (accountUsage: Usage | undefined) => ({
    type: SET_ACCOUNT_USAGE,
    accountUsage
  }),
  setAccountBetaUsage: (accountBetaUsage: Usage | undefined) => ({
    type: SET_ACCOUNT_BETA_USAGE,
    accountBetaUsage
  }),
  setContactResult: (contactResult: Result | undefined) => ({
    type: SET_CONTACT_RESULT,
    contactResult
  }),
  setPasswordResult: (passwordResult: Result | undefined) => ({
    type: SET_PASSWORD_RESULT,
    passwordResult
  }),
  setVerificationResult: (verificationResult: Result | undefined) => ({
    type: SET_VERIFICATION_RESULT,
    verificationResult
  }),
  setScoringLoading: (scoringLoading: boolean | undefined) => ({
    type: SET_SCORING_LOADING,
    scoringLoading: scoringLoading
  }),
  setScoreReport: (scoreReport: ScoreReport | undefined) => ({
    type: SET_SCORE_REPORT,
    scoreReport: scoreReport
  })
};

export const connector = connect(mapState, mapDispatch);

export type connectorType = ConnectedProps<typeof connector>;
