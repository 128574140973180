import React from 'react';
import styled from 'styled-components';
import { Plan } from '../../models/Plan';
import { UsageSummary } from '../../models/Usage';
import { inTrialPeriod } from '../../utils/common';
import { isoDateFormat } from '../../utils/dates';
import { toDollars } from '../../utils/numbers';

interface Props {
  plan: Plan;
  summary: UsageSummary | undefined;
  beta: boolean;
}

const Wrapper = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
`;

const BackgroundBar = styled.div`
  background: var(--gray-light);
  border-radius: 5px;
  width: 100%;
  height: 16px;
  position: absolute;
  left: 0;
  top: 28px;
`;

const UsageBar = styled.div`
  background: var(--primary);
  border-radius: 5px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 28px;

  &.warning {
    background: var(--red);
  }
`;

const Percent = styled.div`
  color: var(--primary);
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 0;

  &.warning {
    color: var(--red);
  }
`;

const Legend = styled.div`
  padding-top: 42px;
  text-align: right;
`;

const OverageDetails = styled.div`
  margin-bottom: 1.2em;
`;

const getLeft = (percent: number): string => {
  if (percent < 5) {
    return percent + '%';
  }
  return 'calc(' + percent + '% - 45px)';
};

const UsageChart: React.FC<Props> = ({ plan, summary, beta }: Props) => {
  if (!plan || !summary) {
    return <></>;
  }

  if (summary.wordLimit === 0) {
    return <></>;
  }

  const inTrial = inTrialPeriod(plan.trialEndDate);

  const currentPeriod =
    isoDateFormat(summary.startDate) + ' — ' + isoDateFormat(summary.endDate);

  const percentUsed = (100 * summary.wordsUsed) / summary.wordLimit;
  const usagePercent = Math.min(percentUsed, 100);
  const warning = usagePercent >= 80;

  let overageDetails: JSX.Element = <></>;
  if (
    !inTrial &&
    plan.overageEnabled &&
    summary.wordsUsed &&
    summary.wordsUsed > plan.wordLimit
  ) {
    const overageWords = summary.wordsUsed - plan.wordLimit;
    const overageCost = plan.overagePricePerWord
      ? toDollars(overageWords * plan.overagePricePerWord)
      : '$0.00';
    overageDetails = (
      <>
        <OverageDetails>
          <h4 className="d-inline">Overage Words:</h4>
          <span>{' ' + overageWords.toLocaleString('en-US')}</span>
        </OverageDetails>
        <OverageDetails>
          <h4 className="d-inline">Overage Cost: </h4>
          <span>{' ' + overageCost}</span>
        </OverageDetails>
      </>
    );
  }

  let overageUsage: JSX.Element = <></>;
  if (
    !inTrial &&
    percentUsed > 100 &&
    plan.overageEnabled &&
    plan.overageWordLimit
  ) {
    const overageWords = summary.wordsUsed - summary.wordLimit;
    const overagePercent = Math.min(
      (100 * overageWords) / plan.overageWordLimit,
      100
    );
    overageUsage = (
      <>
        <Wrapper>
          <Percent
            className="warning"
            style={{ left: getLeft(overagePercent) }}
          >
            {overagePercent.toFixed(1)}%
          </Percent>
          <BackgroundBar />
          <UsageBar
            className="warning"
            style={{ width: overagePercent + '%' }}
          />
          <Legend>
            {Math.max(plan.overageWordLimit - overageWords, 0).toLocaleString(
              'en-US'
            )}{' '}
            Overage Words Remaining
          </Legend>
        </Wrapper>
      </>
    );
  }

  return (
    <>
      {!beta ? (
        <div>
          <h4 className="d-inline">Current Period:</h4>
          <span>{' ' + currentPeriod}</span>
        </div>
      ) : null}
      <Wrapper>
        <Percent
          className={warning ? 'warning' : ''}
          style={{ left: getLeft(usagePercent) }}
        >
          {usagePercent.toFixed(0)}%
        </Percent>
        <BackgroundBar />
        <UsageBar
          className={warning ? 'warning' : ''}
          style={{ width: usagePercent + '%' }}
        />
        <Legend>
          {Math.max(summary.wordLimit - summary.wordsUsed, 0).toLocaleString(
            'en-US'
          )}{' '}
          Words Remaining
        </Legend>
      </Wrapper>
      {overageDetails}
      {overageUsage}
    </>
  );
};

export default UsageChart;
