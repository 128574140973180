import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '../utils/hooks';
import { connector, connectorType } from '../store/connector';
import {
  NavBarBrand,
  NavDropProfile,
  NavBarLogin,
  NavDropLogout,
  NavDropContact
} from './NavLinks';

const SNavbar = styled(Navbar)``;
const SNavLink = styled(NavLink)`
  color: var(--white);
  margin: 0 20px;
  &:hover {
    color: var(--gray-light);
    text-decoration: none;
  }
  font-style: normal;
  font-weight: thin;
  font-size: 14px;
  line-height: 100%;
`;

const SAnchor = styled.a`
  color: var(--white);
  margin: 0 20px;
  &:hover {
    color: var(--gray-light);
    text-decoration: none;
  }
  font-style: normal;
  font-weight: thin;
  font-size: 14px;
  line-height: 100%;
`;

const SNavDropdown = styled(NavDropdown)`
  a {
    color: #fff;
    font-weight: thin;
    font-size: 14px;
    &:hover {
      color: var(--gray-light);
      text-decoration: none;
    }
  }
`;

const TopNav = (props: connectorType): JSX.Element => {
  const { loading, isAuthenticated, user } = useAuth0();

  let links: JSX.Element = <></>;
  if (
    isAuthenticated &&
    props.apiUser &&
    props.apiUser.account.meta.statusId !== 5
  ) {
    links = (
      <Nav.Item>
        <SNavLink to="/">Dashboard</SNavLink>
        <SNavLink to="/score">CSV Upload</SNavLink>
        <SAnchor
          href="https://docs.receptiviti.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </SAnchor>
        <SNavLink to="/contact">Contact Us</SNavLink>
      </Nav.Item>
    );
  } else {
    links = (
      <Nav.Item>
        <SAnchor
          href="https://docs.receptiviti.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </SAnchor>
      </Nav.Item>
    );
  }

  const dropdownOptions =
    isAuthenticated && props.hasAccount ? (
      <>
        <NavDropProfile />
        <NavDropContact />
        <div className="accent-spacer" />
      </>
    ) : (
      <></>
    );

  const dropdown =
    isAuthenticated && user ? (
      <SNavDropdown
        title={user.email}
        id="nav-dropdown"
        className="justify-content-end"
      >
        {dropdownOptions}
        <NavDropLogout />
      </SNavDropdown>
    ) : (
      <NavBarLogin />
    );

  if (loading) {
    return (
      <header>
        <SNavbar className="fixed-top justify-content-between">
          <NavBarBrand />
          <Navbar.Collapse className="justify-content-end">
            LOADING...
          </Navbar.Collapse>
        </SNavbar>
      </header>
    );
  }

  return (
    <header>
      <SNavbar className="fixed-top justify-content-between">
        <NavBarBrand />
        {links}
        {dropdown}
      </SNavbar>
    </header>
  );
};

export default connector(TopNav);
