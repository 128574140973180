import axios, { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';

export type Request<R> = (api: AxiosInstance) => Promise<R>;

type UseRequestState<R> = {
  working: boolean;
  error?: string;
  result?: R;
};

export function useRequest<R>(request?: Request<R>): UseRequestState<R> {
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<R | undefined>();

  useEffect(() => {
    if (!request) {
      setResult(undefined);
      setWorking(false);
      setError(undefined);
      return;
    }
    let active = true;
    const cancelTokenSource = axios.CancelToken.source();

    setWorking(true);
    setError(undefined);

    (async function () {
      const api = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        cancelToken: cancelTokenSource.token
      });

      try {
        const response = await request(api);
        if (active) {
          setResult(response);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError(`Unexpected error occurred: ${JSON.stringify(err)}`);
        }
      }
      if (active) {
        setWorking(false);
      }
    })();

    return () => {
      active = false;
      cancelTokenSource.cancel();
    };
  }, [request]);

  return { working, error, result };
}
